var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("default-layout", [
    _c(
      "div",
      {
        staticClass: "spainhn-solid-red-background d-flex",
        staticStyle: { "min-height": "5vh", width: "100%" },
      },
      [
        _c("div", { staticClass: "d-flex ps-5 my-auto container" }, [
          _c(
            "p",
            {
              staticClass: "my-auto pb-0 mb-0 spainhn-small-text",
              attrs: { role: "button" },
              on: { click: _vm.goToCastillosYPalacios },
            },
            [_vm._v("Spain Heritage Network >> ")]
          ),
          _c(
            "p",
            { staticClass: "my-auto pb-0 mb-0 ms-2 spainhn-small-text" },
            [_vm._v(_vm._s(_vm.$t("default.FOOTER_SELL_GENERALS")) + " ")]
          ),
        ]),
      ]
    ),
    _c("div", { staticClass: "container-fluid px-xl-5 px-1 general-main" }, [
      _c("div", { staticClass: "container-fluid mb-5" }, [
        _c("div", { staticClass: "row text-start margen-estandard px-xl-5" }, [
          _c("div", { staticClass: "d-flex flex-column pt-3 col-12 px-xl-5" }, [
            _c("h5", { staticClass: "mb-0" }, [
              _c("strong", [
                _c("small", [
                  _vm._v(
                    _vm._s(_vm.tipoHeader) + " " + _vm._s(_vm.monumentoNombre)
                  ),
                ]),
              ]),
            ]),
            _c("p", { staticClass: "mb-0" }, [
              _c("strong", [
                _c("small", [
                  _vm._v(
                    _vm._s(_vm.tipoSubheader) +
                      " " +
                      _vm._s(_vm.monumentoNombre)
                  ),
                ]),
              ]),
            ]),
            _c(
              "div",
              {
                staticClass:
                  "h-100 pt-5 ps-3 pe-0 spainhn-text d-flex flex-column",
                staticStyle: { "border-radius": "10px" },
              },
              [
                _c("h1", [_vm._v("CONDICIONES GENERALES DE CONTRATACIÓN")]),
                _c("p", [
                  _vm._v(
                    "Las presentes Condiciones Generales de Contratación se rigen por lo dispuesto en el Real Decreto Legislativo 1/2007, de 16 de noviembre, de la Ley General para la Defensa de los Consumidores y Usuarios y otras leyes complementarias, modificado por el Real Decreto-ley 23/2018, de 21 de diciembre, de trasposición de directivas en materia de marcas, transporte ferroviario y viajes combinados y servicios de viajes vinculados, por la Ley 7/1998, de 13 de abril, sobre condiciones generales de contratación y por lo dispuesto a continuación y Ley 34/2002, de 11 de julio, de servicios de la sociedad de la información y de comercio electrónico."
                  ),
                ]),
                _c("p", [_vm._v(" ")]),
                _c("p", [
                  _vm._v(
                    "Cualquier contratación con SPAIN HERITAGE está sometida a las presentes condiciones generales de venta que a continuación se publicitan libremente, con el fin de que cada CLIENTE pueda conocer y verificar la compra y los términos específicos."
                  ),
                ]),
                _c("p", [_vm._v(" ")]),
                _c("p", [_vm._v(" ")]),
                _c("p", [
                  _vm._v(
                    "El acceso, la navegación y uso del Sitio Web, confiere la condición de usuario (en adelante referido, indistintamente, individualmente como Usuario o conjuntamente como Usuarios), por lo que se aceptan, desde que se inicia la navegación por el Sitio Web, todas las Condiciones aquí establecidas, así como sus ulteriores modificaciones, sin perjuicio de la aplicación de la correspondiente normativa legal de obligado cumplimiento según el caso."
                  ),
                ]),
                _c("p", [_vm._v(" ")]),
                _c("p", [_vm._v(" ")]),
                _c("p", [
                  _vm._v(
                    "Las CONDICIONES GENERALES tienen por objeto regular los términos contractuales para la prestación de los SERVICIOS por parte de SPAIN HERITAGE, y, en su caso, la contraprestación debida por el CLIENTE a SPAIN HERITAGE, por la utilización por aquél de determinados SERVICIOS. La prestación de los SERVICIOS se llevará a cabo a mediante la obtención, disposición, organización, empleo y gestión por parte de SPAIN HERITAGE de los recursos técnicos, humanos y operativos necesarios al efecto y, siempre y en todo caso, como contraprestación a los precios vigentes en cada momento cuando los SERVICIOS sean de pago. La mera navegación por la web https://www.spainheritagenetwork.com no tendrá la consideración de SERVICIO."
                  ),
                ]),
                _c("p", [_vm._v(" ")]),
                _c("p", [_vm._v(" ")]),
                _c("p", [
                  _vm._v(
                    "SPAIN HERITAGE vela y protege especialmente al viajero en los términos que se detallan en las presentes CONDICIONES GENERALES, así como en el AVISO LEGAL y POLÍTICA DE PRIVACIDAD."
                  ),
                ]),
                _c("p", [_vm._v(" ")]),
                _c("p", [_vm._v(" ")]),
                _c("p", [
                  _vm._v(
                    "La información contenida en las presentes CONDICIONES GENERALES de viajes combinados y aquellas que se facilitan en el momento precontractual, tienen carácter genérico. No son de esperar variaciones significativas en el contenido y serán modificadas exclusivamente en los términos que se recojan en las CONDICIONES PARTICULARES y en determinados supuestos que se exponen más adelante."
                  ),
                ]),
                _c("p", [_vm._v(" ")]),
                _c("p", [_vm._v(" ")]),
                _c("p", [_vm._v("PROCESO DE COMPRA O ADQUISICIÓN")]),
                _c("p", [_vm._v(" ")]),
                _c("p", [_vm._v(" ")]),
                _c("p", [
                  _vm._v(
                    "Los Usuarios debidamente registrados pueden comprar en el Sitio Web por los medios y formas establecidos. Deberán seguir el procedimiento de compra y/o adquisición online de SPAIN HERITAGE, durante el cual varios productos y/o servicios pueden ser seleccionados y añadidos al espacio final de compra y, finalmente, hacer clic en “Comprar”. Seguidamente, el Usuario recibirá un correo electrónico confirmando que SPAIN HERITAGE ha recibido su pedido o solicitud de compra y/o prestación del servicio, es decir, la confirmación del pedido. Y, en su caso, se le informará, igualmente, mediante correo electrónico cuando su compra este pendiente de confirmación por el proveedor o las variaciones en relación a la respuesta obtenida de éstos."
                  ),
                ]),
                _c("p", [_vm._v(" ")]),
                _c("p", [_vm._v(" ")]),
                _c("p", [_vm._v("DISPONIBILIDAD Y RESERVA")]),
                _c("p", [_vm._v(" ")]),
                _c("p", [_vm._v(" ")]),
                _c("p", [
                  _vm._v(
                    "Todos los pedidos de compra recibidos a través del Sitio Web están sujetos a la disponibilidad de los prestadores de servicios, actuando SPAIN HERITAGE en relación a los servicios sueltos como mero intermediario, y en relación a los paquetes turísticos (viaje combinado) actúa como organizador de los mismos."
                  ),
                ]),
                _c("p", [_vm._v(" ")]),
                _c("p", [_vm._v(" ")]),
                _c("p", [
                  _vm._v(
                    "La compra de cualquier producto sólo será efectiva en el momento en que SPAIN HERITAGE realice el cargo del importe total del viaje de manera válida en la tarjeta de crédito suministrada por el Usuario o justificante de la transferencia, pudiendo hasta dicho momento ser cancelada por parte SPAIN HERITAGE sin que el usuario tenga derecho a reclamación alguna."
                  ),
                ]),
                _c("p", [_vm._v(" ")]),
                _c("p", [_vm._v(" ")]),
                _c("p", [
                  _vm._v(
                    "La solicitud de una reserva implica el compromiso por parte del cliente de autorizar el cargo en la tarjeta de crédito que suministra. En el caso de que no fuera posible realizar el cargo en la tarjeta de crédito, por circunstancias ajenas a la gestión de SPAIN HERITAGE el Usuario acepta que SPAIN HERITAGE no emitirá la reserva efectuada."
                  ),
                ]),
                _c("p", [_vm._v(" ")]),
                _c("p", [_vm._v("MEDIOS TÉCNICOS PARA CORREGIR ERRORES")]),
                _c("p", [
                  _vm._v(
                    "Se pone en conocimiento del Usuario que en caso de que detecte que se ha producido un error al introducir datos necesarios para procesar su solicitud de compra en el Sitio Web, podrá modificar los mismos poniéndose en contacto con SPAIN HERITAGE a través de los espacios de contacto habilitados en el Sitio Web, y, en su caso, a través de aquellos habilitados para contactar con el servicio de atención al cliente, info@spainheritagenetwork.com y/o utilizando los datos de contacto facilitados en el aviso legal."
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "En cualquier caso, el Usuario, antes de hacer clic en “confirmar compra”, tiene acceso al espacio, donde se va anotando su solicitud de compra, donde podrá revisar y en su caso realizar modificaciones."
                  ),
                ]),
                _c("p", [_vm._v("DERECHO DE DESISTIMIENTO")]),
                _c("p", [
                  _vm._v(
                    "Es la facultad que tiene el usuario de dejar sin efecto un contrato en determinados casos sin necesidad de justificar su decisión ni asumir penalización alguna."
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "En virtud del artículo 103 apartado l) del Real Decreto Legislativo 1/2007, de 16 de noviembre, por el que se aprueba el texto refundido de la Ley General para la Defensa de los Consumidores y Usuarios y otras leyes complementarias, no será aplicable el derecho de desistimiento a los contratos que se refieran a suministro de servicios de alojamiento para fines distintos del de servir de vivienda, transporte de bienes, alquiler de vehículos, comida o servicios relacionados con actividades de esparcimiento, si los contratos prevén una fecha o un periodo de ejecución específicos."
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "En cuanto al transporte de personas, según el artículo 93 k) del Real Decreto Legislativo 1/2007 por el que se aprueba el Texto Refundido de la Ley General para la Defensa de los Consumidores y Usuarios la regulación de las ventas a distancia (a excepción del artículo 98.2) no resulta de aplicación a los contratos de servicio de transporte de pasajeros. Por lo que en las reservas de servicios de transporte de personas no resulta de aplicación el derecho de desistimiento. De este modo, en el caso de que el Usuario manifieste su voluntad de cancelar los billetes contratados le serán de aplicación las condiciones de cancelación de la compañía de que se trate."
                  ),
                ]),
                _c("h2", [_vm._v("1.- DEFINICIONES")]),
                _c("p", [
                  _vm._v(
                    "A los efectos de las presentes CONDICIONES GENERALES se entiende por:"
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "Servicio suelto: adquisición de un servicio, sin combinación con otros, ni en el porcentaje estipulado en su normativa para ser considerado viaje combinado o vinculado. Serán considerado un servicio suelto, con carácter enunciativo y no limitativo la adquisición de la entrada a los monumentos, el alojamiento turístico, y cualquier otro servicio que se ofrezca de manera independiente y sin paquetizar. Los servicios sueltos están sometidos a sus condiciones particulares de venta cuya información se facilita desde el portal."
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "Viaje combinado: es aquel en el que se combinan, al menos, dos servicios de viaje a efectos del mismo viaje o vacación, siempre que la combinación se formalice en un único contrato con independencia de que lo solicite o no el viajero. También se considera viaje combinado aquel que, a pesar de formalizarse en varios contratos, Se realice en uno de nuestros puntos de venta."
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "Se seleccione la combinación de servicios de viaje antes de que el viajero consienta pagar. Se ofrezca, se venda o se facture por el organizador, o en su caso el minorista, a un precio a tanto alzado o global. Se anuncie por el organizador, o en su caso el minorista, como “viaje combinado”."
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "Dichos contratos faculten al viajero a elegir entre una selección de distintos servicios de viaje. Por último, también son contratos de viaje combinado, aquellos contratos formalizados en https://www.spainheritagenetwork.com (a través de un proceso de reserva en línea conectado), en el que el viajero celebra un único contrato con el empresario, pero varios con los prestadores de los servicios contratados por el viajero a más tardar dentro de las 24 horas siguientes a la confirmación de la última reserva. También se considera viaje combinado aquel que combine un servicio de viaje (transporte, alojamiento y alquiler de vehículo) y adicionalmente, uno o más servicios de turísticos, siempre que: Los servicios turísticos representen una proporción igual o superior al 25% del valor de la combinación y se anuncien como una característica esencial de la combinación o; Si los servicios turísticos solo han sido contratados después de que se haya iniciado la ejecución de 1 servicio de viaje (transporte, alojamiento y alquiler de vehículo)."
                  ),
                  _c("br"),
                  _vm._v(
                    " • Servicio de viaje: Son servicios de viaje, el transporte de pasajeros, el alojamiento cuando no sea parte integrante del transporte de pasajeros y no tenga un fin residencial, el alquiler de turismos (Real Decreto 750/2010, de 4 de junio) y, cualquier otro servicio turístico que no forme parte integrante de un servicio de viaje de los citados anteriormente."
                  ),
                  _c("br"),
                  _vm._v(
                    " • Contrato de Viaje Combinado: es aquel que se formaliza en un solo contrato por el total del conjunto del viaje o, la formalización de varios contratos por cada uno de los servicios de viaje incluidos en el viaje combinado ofertado."
                  ),
                  _c("br"),
                  _vm._v(
                    " • Fecha de inicio del viaje combinado: es aquel día en el que comienza la ejecución de los servicios de viaje incluidos en el contrato."
                  ),
                  _c("br"),
                  _vm._v(
                    " • Repatriación: el regreso del viajero al lugar de salida o a cualquier otro lugar acordado por las partes contratantes."
                  ),
                  _c("br"),
                  _vm._v(
                    " • Falta de conformidad: la no ejecución o la ejecución incorrecta de los servicios de viaje incluidos en un viaje combinado."
                  ),
                  _c("br"),
                  _vm._v(
                    " • Viajero: toda persona que tiene intención de celebrar un contrato o tiene derecho a viajar en virtud de un contrato."
                  ),
                  _c("br"),
                  _vm._v(
                    " • Empresario: aquel que atiende a los viajeros de manera presencial o en línea, tanto si actúa como organizador, minorista, empresario que facilita servicios de viaje o como prestador de servicios de viajes."
                  ),
                  _c("br"),
                  _vm._v(
                    " • Organizador: se considera a aquel empresario que combina y vende u oferta viajes combinados directamente, a través de o junto con otro empresario."
                  ),
                  _c("br"),
                  _vm._v(
                    " • Minorista: aquel empresario distinto del organizador que vende u oferta los viajes combinados por un organizador."
                  ),
                  _c("br"),
                  _vm._v(
                    " • Menor: toda persona menor de dieciocho años de edad."
                  ),
                  _c("br"),
                  _vm._v(
                    " • Circunstancias inevitables y extraordinarias: se considerarán aquellas situaciones que están fuera de control de la parte que alega la situación y cuyas consecuencias no habrían podido evitarse incluso si se hubieran adoptado todas las medidas razonables."
                  ),
                  _c("br"),
                  _vm._v(
                    " • Punto de venta: hace referencia a toda instalación de venta al por menor, tanto muebles como inmueble, o a un sitio web de venta al por menor o a un dispositivo similar de venta minorista en línea, incluso cuando estos sitios web o dispositivos se presenten a los viajeros como un dispositivo único, incluido un servicio telefónico."
                  ),
                  _c("br"),
                  _vm._v(
                    " • Establecimiento: el acceso a una actividad económica no asalariada y su ejercicio, así como la constitución y gestión de empresas y especialmente de sociedades, en las condiciones fijadas por la legislación, por una duración indeterminada, en particular por medio de una infraestructura estable. Información precontractual: Se entiende por información precontractual, aquella que el organizador o, en su caso, el minorista está obligado a facilitar al viajero antes de que el viajero quede obligado por cualquier contrato u oferta."
                  ),
                ]),
                _c("h2", [_vm._v("2. ORGANIZACIÓN DEL VIAJE COMBINADO")]),
                _c("p", [
                  _vm._v(
                    "La organización de los viajes combinados se realiza por la agencia mayorista, o mayorista-minorista en su caso, detallada en la información precontractual facilitada al cliente y en el contrato de viaje combinado."
                  ),
                ]),
                _c("h2", [
                  _vm._v("3. PROCESO DE CONTRATACIÓN DE UN VIAJE COMBINADO"),
                ]),
                _c("p", [
                  _vm._v(
                    "SPAIN HERITAGE ofrecerá al viajero interesado un programa (información precontractual) que contendrá la descripción del viaje combinado de acuerdo con el contenido del artículo 153 del Real Decreto Legislativo 1/2007. El consumidor que acepte el programa ofertado suscribirá con el organizador un contrato de viaje combinado de acuerdo con el contenido del artículo 155 del Real Decreto Legislativo 1/2007 y tras el pago por parte del primero del importe establecido en el mismo. Formarán parte integrante del contrato de viaje combinado, todas las condiciones que regulan el viaje combinado contratado, en concreto, las CONDICIONES GENERALES dispuestas en este texto, las condiciones del programa, y las condiciones particulares pactadas entre las partes. SPAIN HERITAGE se compromete a entregar al viajero una copia del contrato de viaje combinado que formalicen las partes, a más tardar en el plazo de 24 horas a contar desde dicha formalización. SPAIN HERITAGE realizará las gestiones oportunas para obtener de cada proveedor la confirmación de los servicios de viaje."
                  ),
                  _c("br"),
                  _vm._v(
                    " En el supuesto de que alguno de estos servicios de viaje no pudiera ser confirmado por falta de disponibilidad, se ofrecerá al viajero en su lugar otro de características similares, expresado el nuevo precio, que el cliente podrá aceptar o rechazar."
                  ),
                  _c("br"),
                  _vm._v(
                    " En el caso de que el cliente rechace el cambio propuesto, SPAIN HERITAGE reembolsará los importes depositados a la fecha y en el caso de que el cliente acepte el cambio propuesto éste se reflejará en un Anexo que se incorporará al contrato de viaje combinado inicialmente firmado entre las partes."
                  ),
                ]),
                _c("h2", [_vm._v("4.- DELIMITACIÓN DE LOS SERVICIOS")]),
                _c("p", [
                  _c("br"),
                  _vm._v(" 4.1.- Transportes"),
                  _c("br"),
                  _vm._v(
                    " El cliente debe presentarse en el lugar indicado el día de la fecha de inicio de viaje combinado con la antelación prevista en la documentación de viaje facilitada. La presentación en el punto de salida del transporte contratado se efectuará con un mínimo de antelación de 1 horas y en todo caso se seguirán estrictamente las recomendaciones específicas que indique la documentación del viaje facilitada al suscribir el contrato. Si el consumidor no pudiera realizar el viaje por no haberse presentado con la antelación requerida, se aplicará el régimen previsto en lo relativo a la falta de presentación a la salida por parte del cliente. Las reservas se realizarán con los datos indicados por el cliente. Los datos erróneamente facilitados por los pasajeros pueden ser constitutivo de denegación por parte del prestador del servicio de la prestación del mismo, siendo responsable el viajero de las consecuencias que se deriven del mismo."
                  ),
                  _c("br"),
                  _vm._v(
                    " Los errores en la reserva imputables al cliente debido a un error en los datos o a una información proporcionada que sea incompleta, en ningún caso será responsabilidad de SPAIN HERITAGE La pérdida o daño que se produzca en relación con el equipaje u otros objetos que el cliente lleve consigo, y conserve bajo su custodia, son de su exclusiva cuenta y riesgo o, en su caso, de la de la compañía prestadora de los servicios de viaje de transporte."
                  ),
                  _c("br"),
                  _vm._v(" 4.2.- Alojamiento"),
                  _c("br"),
                  _vm._v(" 4.2.1.- General"),
                  _c("br"),
                  _vm._v(
                    " La calidad y contenido de los servicios prestados por el alojamiento turístico vendrá determinada por la categoría turística oficial, si la hubiere, asignada por el órgano competente del país en el que se encuentre. Las habitaciones triples y/o cuádruples son generalmente habitaciones dobles a las que se añade una o dos camas, que suelen ser un sofá-cama o una cama supletoria (plegatín), excepto en ciertos establecimientos donde en lugar de camas adicionales existen dos camas de tamaño superior al estándar debiendo pernoctar dos personas en cada una de ellas. El horario habitual para la entrada y salida en los alojamientos turísticos está determinado en función del primer y último servicio que el cliente vaya a utilizar. Como norma general, y salvo que expresamente se pacte otra cosa, las habitaciones podrán ser utilizadas a partir de las 15 horas del día de llegada y deberán quedar libres antes de las 12 horas del día de salida, con independencia de la hora en que esté prevista la llegada al hotel o de la hora en que esté prevista la continuación del viaje."
                  ),
                  _c("br"),
                  _vm._v(
                    " Cuando el usuario prevea su llegada al alojamiento reservado en fechas u horas distintas a las reseñadas, es conveniente, para evitar problemas y malas interpretaciones, comunicar con la mayor anticipación posible tal circunstancia al organizador y al alojamiento turístico directamente, según el caso. Igualmente, debe el usuario consultar al organizador, en el momento de hacer la reserva, la posibilidad de llevar animales domésticos, pues generalmente no son admitidos en los alojamientos turísticos. En el supuesto de haber confirmado la admisión de animales domésticos y se pretenda viajar con ellos, tal circunstancia deberá ser comunicada por parte del cliente a SPAIN HERITAGE para hacerse constar tal circunstancia en el contrato."
                  ),
                  _c("br"),
                  _vm._v(
                    " El servicio de alojamiento turístico implicará que la habitación esté disponible en la noche correspondiente, entendiéndose prestado con independencia de que, por circunstancias propias del viaje, el horario de entrada en el mismo se produzca más tarde de lo inicialmente previsto."
                  ),
                  _c("br"),
                  _c("br"),
                  _vm._v(" 4.2.2.-Servicios Suplementarios"),
                  _c("br"),
                  _vm._v(
                    " Cuando los viajeros soliciten servicios suplementarios (por ejemplo habitación vista al mar, etc.) que no puedan ser confirmados con seguridad por SPAIN HERITAGE, el viajero podrá optar por desistir, por escrito, definitivamente del servicio suplementario solicitado o mantener su solicitud a la espera de que tales servicios suplementarios puedan finalmente serle prestados siéndole confirmados a su llegada al alojamiento turístico."
                  ),
                  _c("br"),
                  _vm._v(
                    " En el supuesto de que las partes hubieran convenido el pago previo del precio de los servicios suplementarios y que estos finalmente no pudieran ser prestados, el importe abonado será reembolsado por SPAIN HERITAGE en los plazos legalmente establecidos, bien al desistimiento del servicio por parte del consumidor o al regreso del viaje, según el caso."
                  ),
                  _c("br"),
                  _c("br"),
                  _vm._v(" 4.3.- Régimen alimenticio"),
                  _c("br"),
                  _vm._v(
                    " Por defecto, y salvo que se especifique lo contrario en el programa y en el contrato, el régimen alimenticio de los alojamientos contratados es de “alojamiento y desayuno”."
                  ),
                  _c("br"),
                  _vm._v(
                    " El régimen de media pensión, salvo que se indique cosa en contrario, incluye desayuno continental y cena. Por regla general, y a menos que se indique expresamente lo contrario, dichas cenas no incluyen el precio de las bebidas. Las dietas especiales (vegetarianas o de regímenes especiales) sólo se garantizan si han sido avisadas antes del inicio del viaje por parte del viajero a SPAIN HERITAGE y se ha confirmado la disponibilidad por parte del prestador del servicio."
                  ),
                  _c("br"),
                  _vm._v(
                    " Si por causa de retraso, sea cual fuere, se perdiera algún servicio de restauración correspondiente a la pensión alimenticia contratada, no existirá derecho a devolución alguna."
                  ),
                  _c("br"),
                  _vm._v(" 4.4.- Condiciones económicas especiales para niños"),
                  _c("br"),
                  _vm._v(
                    " Dada la diversidad de las condiciones aplicables a los viajeros menores de edad, dependiendo del proveedor de servicios y de la fecha del viaje, es obligatorio que el viajero informe de la edad de los miembros del grupo y consulte siempre el alcance de las condiciones especiales aplicables que existan y que en cada momento serán objeto de información concreta y detallada. Estas cuestiones se recogerán en el contrato y/o en la documentación del viaje que se entregue."
                  ),
                ]),
                _c("h2", [_vm._v("5.- SERVICIOS EN VIAJE COMBINADO")]),
                _c("p", [
                  _vm._v("5.1. El precio del viaje"),
                  _c("br"),
                  _vm._v(" El precio del viaje incluye:"),
                  _c("br"),
                  _c("br"),
                  _vm._v(
                    " • El precio de todos los servicios de viaje contratados (transporte de pasajeros, alojamiento, alquiler de vehículos de motor, etc.), con independencia de si se ha formalizado en un contrato o en varios cuando dichos servicios estén incluidos en el programa contratado con las especificidades que consten en el mismo;"
                  ),
                  _c("br"),
                  _vm._v(
                    " . Las comisiones recargos y costes adicionales, salvo que se indique lo contrario en el contrato de viaje combinado;"
                  ),
                  _c("br"),
                  _vm._v(
                    " . Las tasas (o impuestos indirectos cuando sean aplicables), salvo que se indique lo contrario en el contrato de viaje combinado;"
                  ),
                  _c("br"),
                  _vm._v(" . Los gastos de gestión;"),
                  _c("br"),
                  _vm._v(
                    " . La prestación de asistencia por parte de SPAIN HERITAGE y/o de los proveedores de servicios de viaje en destino si el viajero se halla en dificultades, especialmente en supuestos de circunstancias extraordinarias e inevitables y las dispuestas en la normativa de aplicación;"
                  ),
                  _c("br"),
                  _vm._v(
                    " . La posibilidad de solicitar asistencia cuando tenga dificultades para presentar una reclamación por cualquier falta de conformidad del viajero durante el viaje."
                  ),
                  _c("br"),
                  _vm._v(" El precio del viaje no incluye:"),
                  _c("br"),
                  _vm._v(
                    " Todo aquello que no esté expresamente recogido en el contrato de viaje como visados, certificados de vacunación, tributos y/o tasas turísticas de entrada y/o salidas, extras (cafés, vinos, licores, aguas minerales, regímenes alimenticios especiales salvo que se haya indicado en el contrato de viaje combinado, lavado y planchado de ropa, parking, utilización del teléfono, cunas, alquiler de TV, servicios de Spa, balnearios, termas, masajes, tratamientos médicos, terapéuticos o de salud) y cualquier otro servicio similar que el alojamiento turístico ofrezca por un precio independiente al del servicio de alojamiento contratado, así como aquellos impuestos adicionales en determinados destinos, que pueden variar en función de la categoría del establecimiento y del destino."
                  ),
                  _c("br"),
                  _vm._v(
                    " Las tasas turísticas se abonarán directamente en el establecimiento hotelero y será el viajero el único responsable de informarse sobre la existencia de las mismas y de su abono y cualesquiera otros análogos."
                  ),
                  _c("br"),
                  _vm._v(
                    " En el caso de excursiones o visitas facultativas no contratadas en origen, éstas no forman parte del contrato de viaje no pudiendo ser responsable SPAIN HERITAGE de la correcta prestación de las mismas. De no contratarse en origen, su propuesta tiene mero carácter informativo y por ello, en el momento de contratarse en destino, pueden producirse variaciones en sus costes que alteren el precio estimado. Dichas excursiones o visitas facultativas serán informadas al cliente con sus condiciones estimadas no pudiendo garantizar SPAIN HERITAGE la posible prestación por parte de terceros de las mismas."
                  ),
                  _c("br"),
                  _vm._v(
                    " Dentro del precio del viaje combinado no están incluidas las propinas."
                  ),
                  _c("br"),
                  _vm._v(" 5.2. Modificaciones del precio"),
                  _c("br"),
                  _vm._v(
                    " El precio del viaje ha sido calculado en base a los tipos de cambio, tarifas de transporte, coste del carburante. Debido a la constante subida del petróleo o en otras fuentes de energía, en ocasiones, el precio del viaje podrá ser modificado posteriormente a la contratación y confirmación del viaje, por SPAIN HERITAGE siempre que dicha facultad se disponga en el contrato formalizado."
                  ),
                  _c("br"),
                  _vm._v(
                    " Dependiendo del destino se impondrán al viajero, como parte integrante del precio total del viaje combinado, las tasas, impuestos y recargos turísticos Dichos importes podrán ser modificados por aquellos terceros que no están involucrados directamente en la ejecución del viaje combinado y, como consecuencia de ello, podrán ser modificados por SPAIN HERITAGE."
                  ),
                  _c("br"),
                  _vm._v(
                    " Toda modificación en el precio podrá ser revisada, tanto al alza como a la baja, en los supuestos en que haya habido modificación del precio de los transportes, incluido el coste del carburante, las tasas e impuestos relativos a determinados servicios contratados y los cambios en el tipo de divisa aplicables. Dicha revisión solo podrá efectuarse con una antelación a más tardar veinte días naturales antes del inicio del viaje combinado, debiendo ser ésta comunicada al viajero con la justificación del incremento y su cálculo en soporte duradero."
                  ),
                  _c("br"),
                  _vm._v(
                    " El viajero tendrá derecho a una reducción del precio correspondiente a toda disminución de los costes mencionados en este apartado que se produzcan entre la fecha de confirmación de reserva de viaje combinado y fecha de inicio del mismo. En caso de reducción del precio, SPAIN HERITAGE tendrá derecho a deducir los gastos administrativos reales del reembolso debido al viajero."
                  ),
                  _c("br"),
                  _vm._v(
                    " El viajero podrá resolver el contrato sin penalización alguna si la revisión significara una modificación superior al 8% del precio total del viaje."
                  ),
                  _c("br"),
                  _vm._v(
                    " 5.3. Reducción del precio e indemnización por daños y perjuicios"
                  ),
                  _c("br"),
                  _vm._v(
                    " El viajero tendrá derecho a una reducción del precio adecuada por cualquier periodo durante el cual haya habido falta de conformidad, a menos que SPAIN HERITAGE demuestre que la falta de conformidad es imputable al viajero. Asimismo, el viajero tendrá derecho a recibir una indemnización adecuada de SPAIN HERITAGE por cualquier daño o perjuicio que sufra como consecuencia de cualquier falta de conformidad. La indemnización se abonará sin demora indebida. El viajero no tendrá derecho a una indemnización por daños y perjuicios si SPAIN HERITAGE demuestra que la falta de conformidad es imputable al viajero, imputable a un tercero ajeno a la prestación de los servicios contratados e imprevisible o inevitable, o debida a circunstancias inevitables y extraordinarias."
                  ),
                  _c("br"),
                  _vm._v(
                    " En la medida en que los convenios internacionales que vinculan a la Unión Europea limiten el alcance o las condiciones del pago de indemnizaciones por parte de prestadores de servicios de viaje incluidos en un viaje combinado, las mismas limitaciones se aplicarán SPAIN HERITAGE En los demás casos, el contrato podrá limitar la indemnización que debe pagar SPAIN HERITAGE siempre que esa limitación no se aplique a los daños corporales o perjuicios causados de forma intencionada o por negligencia y que su importe no sea inferior al triple del precio total del viaje. Todo derecho a indemnización o reducción del precio en virtud de lo establecido en la ley de aplicación no afectará a los derechos de los viajeros contemplados en la legislación europea y los viajeros tendrán derecho a presentar reclamaciones con arreglo a ley aplicable, a dichos reglamentos y a los convenios internacionales. La indemnización o reducción del precio concedida en virtud de la ley y la concedida en virtud de dichos reglamentos y convenios internacionales, se deducirán la una de la otra para evitar el exceso de indemnización."
                  ),
                  _c("br"),
                  _vm._v(" 5.4. Forma de pago"),
                  _c("br"),
                  _vm._v(
                    " En el momento de la firma del contrato el viajero deberá abonar al organizador un anticipo que en ningún caso será superior al 40% de la suma del importe del viaje combinado."
                  ),
                  _c("br"),
                  _vm._v(
                    " El importe restante hasta completar el precio total el viaje combinado, según calendario de pagos informado al cliente con anterioridad a la contratación del viaje, en el programa, y establecido en el contrato salvo en aquellos supuestos determinados en los que a razón del momento de la firma del contrato y la fecha de salida del viaje el pago se efectuará en su integridad sin sometimiento a calendario de pago alguno."
                  ),
                  _c("br"),
                  _vm._v(
                    " Si alguno de los servicios contratados tuviera condiciones especiales de pago, se informará de las mismas en el programa antes de la contratación del viaje y éstas se reflejarán en el contrato."
                  ),
                  _c("br"),
                  _vm._v(
                    " Si el viajero no realiza el pago final establecido en el contrato, SPAIN HERITAGE le requerirá para que lo efectúe en el plazo de 48 horas. De no procederse a este pago en tal plazo o de incumplirse cualesquiera de los pagos establecidos en el calendario, SPAIN HERITAGE podrá resolver el contrato y aplicar las reglas establecidas para el desistimiento antes de la salida por parte del consumidor que, de tener especificidades, éstas serán indicadas en el programa y el contrato. El viaje combinado debe estar totalmente pagado para que se le faciliten al viajero todas las prestaciones de su viaje. En el caso de que el cobro sea rechazado por cualquier causa, se procederá a la anulación de la reserva, previa comunicación informativa para intentar solventar el problema del pago."
                  ),
                  _c("br"),
                  _vm._v(
                    " Una vez efectuada la salida del viaje, la no utilización voluntaria por parte del consumidor de alguno de los servicios contratados, no le dará derecho a devolución alguna."
                  ),
                  _c("br"),
                  _vm._v(" 5.5. Ofertas especiales"),
                  _c("br"),
                  _vm._v(
                    " Cuando se realice la contratación del viaje combinado como consecuencia de ofertas especiales, de última hora o equivalentes, a precio distinto del expresado anteriormente, los servicios comprendidos en el precio son únicamente aquellos que se especifican detalladamente en la oferta, aun cuando, dicha oferta haga referencia a alguno de los programas descritos en este programa, siempre que dicha remisión se realice a los exclusivos efectos de información general del destino."
                  ),
                ]),
                _c("h2", [
                  _vm._v("6.- MODIFICACIÓN DEL CONTRATO ANTES DE LA SALIDA"),
                ]),
                _c("p", [
                  _vm._v(
                    "En cualquier caso los cambios serán reflejados en un Anexo que se incorporará al contrato de viaje combinado inicialmente firmado entre las partes."
                  ),
                  _c("br"),
                  _vm._v(" 6.1.- Por el organizador"),
                  _c("br"),
                  _vm._v(
                    " SPAIN HERITAGE, antes de la fecha de salida del viaje, se reserva su derecho a realizar aquellos cambios que sean necesarios para el buen fin del mismo siempre que estos no sean significativos y que se informarán al viajero de forma clara, comprensible y destacada en un soporte duradero."
                  ),
                  _c("br"),
                  _vm._v(
                    " En el supuesto de que SPAIN HERITAGE se vea obligada a realizar cambios sustanciales en alguna de las principales características de los servicios de viaje o no pueda cumplir con los requisitos relativos a las necesidades especiales o proponga al viajero aumentar el precio del viaje combinado en más de un 8% del total del precio, SPAIN HERITAGE lo pondrá inmediatamente en conocimiento del cliente y este podrá optar entre aceptar la modificación del contrato y su repercusión en el precio, o la resolución del contrato. El cliente deberá comunicar por escrito la decisión que adopte a SPAIN HERITAGE dentro de los tres días siguientes al día en el que se le notifique la modificación. En el caso de que el viaje combinado sustitutivo al modificado sea de calidad o coste inferior, el viajero tendrá derecho a una reducción en el precio."
                  ),
                  _c("br"),
                  _vm._v(" 6.2.- Por el cliente"),
                  _c("br"),
                  _vm._v(
                    " 6.2.1.- Modificación de las características del viaje combinado"
                  ),
                  _c("br"),
                  _vm._v(
                    " Si en cualquier momento anterior a la fecha de salida, el viajero desea solicitar cambios referidos a cualesquiera de las características del viaje combinado contratado y SPAIN HERITAGE puede efectuarlos, SPAIN HERITAGE podrá exigirle al viajero el abono de los gastos adicionales justificados que hubiese causado dicha modificación. En este supuesto los precios y características de los servicios turísticos podrán no corresponderse con los publicados en el programa o información precontractual que dio lugar a la contratación."
                  ),
                  _c("br"),
                  _vm._v(" 6.2.2.- Cesión del contrato de viaje combinado"),
                  _c("br"),
                  _vm._v(
                    " El viajero podrá ceder su reserva en el viaje combinado a una tercera persona siempre y cuando éste reúna todas las condiciones requeridas para el citado viaje, debiéndolo comunicar por escrito a SPAIN HERITAGE con una antelación mínima de 7 días a la fecha del inicio del viaje, salvo que las partes acuerden un plazo menor en el contrato, quien gestionará con el proveedor e informará de la posibilidad o no de poder llevar a cabo dicha cesión. Tanto el cedente como el cesionario responderán solidariamente del pago del precio del viaje así como de cualquier gasto adicional justificado que pudiera causar dicha cesión."
                  ),
                ]),
                _c("h2", [_vm._v("7.- RESOLUCIÓN DEL CONTRATO")]),
                _c("p", [
                  _vm._v(
                    "7.1. Resolución del viaje por parte de SPAIN HERITAGE"
                  ),
                  _c("br"),
                  _vm._v(
                    " En el supuesto de que SPAIN HERITAGE cancele el viaje combinado antes de la fecha de salida acordada, por cualquier motivo que no le sea imputable al cliente, éste tendrá derecho, desde el momento en que se produzca la resolución del contrato, al reembolso de todas las cantidades pagadas, con arreglo al mismo, o bien a la realización de otro viaje combinado de calidad equivalente o superior, siempre que el organizador pueda proponérselo. En el supuesto de que el viaje ofrecido fuera de calidad inferior, el organizador deberá reembolsar al cliente, cuando proceda, en función de las cantidades ya desembolsadas, la diferencia del precio, con arreglo al contrato."
                  ),
                  _c("br"),
                  _vm._v(
                    " En los casos que la propuesta de viaje combinado esté condicionada a contar con un mínimo de participantes (35 personas en viajes de autocar y 20 personas en viajes aéreos) y por no alcanzarse ese número se produzca la anulación del mismo, SPAIN HERITAGE deberá notificárselo por escrito al cliente antes de la fecha límite fijada a tal fin, que como mínimo será de 20 días de antelación a la fecha prevista de iniciación del viaje y al reembolso de todas las cantidades entregadas hasta ese momento."
                  ),
                  _c("br"),
                  _vm._v(
                    " En el caso de que SPAIN HERITAGE resuelva el contrato de viaje combinado, deberá reembolsar al viajero las cantidades abonadas por el mismo, en un plazo no superior a catorce días naturales a contar desde la fecha de resolución del contrato."
                  ),
                  _c("br"),
                  _vm._v(" 7.2. Resolución del viaje por parte del viajero"),
                  _c("br"),
                  _vm._v(" (*) Derecho de desistimiento:"),
                  _c("br"),
                  _vm._v(
                    " Conforme al art. 97.1 del TRLGDU, se informa al usuario que, en el caso de servicios de viaje combinado no será de aplicación el ejercicio del derecho de desistimiento, en virtud de lo dispuesto en su art. 103. Los servicios de viaje, transporte, comidas o actividades relacionadas con las actividades de esparcimiento están excluidos en la normativa aplicable de consumidores, en relación al ejercicio del derecho de desistimiento por parte del consumidor."
                  ),
                  _c("br"),
                  _vm._v(
                    " Así y en virtud del artículo 103 apartado l) del Real Decreto Legislativo 1/2007, de 16 de noviembre, por el que se aprueba el texto refundido de la Ley General para la Defensa de los Consumidores y Usuarios y otras leyes complementarias, no será aplicable el derecho de desistimiento a los contratos de viaje combinado, debiendo el consumidor asumir los gastos derivados de la cancelación de servicios solicitada por su parte. Únicamente aquellos viajeros que contraten un viaje combinado fuera del establecimiento (no contratación a distancia), según definido en el RDL 1/2007, de 16 de diciembre, dispondrán del plazo de catorce días para ejercer su derecho ante SPAIN HERITAGE."
                  ),
                  _c("br"),
                  _vm._v(
                    " Aclarado lo anterior, en todo momento anterior a la fecha de salida el viajero resolver el contrato de viaje combinado, teniendo derecho a la devolución de las cantidades que hubiera abonado, y debiendo abonar a SPAIN HERITAGE una penalización por los conceptos que a continuación se indican, salvo que la resolución tenga lugar por causa de fuerza mayor:"
                  ),
                  _c("br"),
                  _vm._v(" Gastos de gestión:"),
                  _c("br"),
                  _vm._v(" En el caso de servicios sueltos 10% por persona"),
                  _c("br"),
                  _vm._v(" En el caso de viajes combinados 10% por persona"),
                  _c("br"),
                  _vm._v(" Más los gastos de anulación que hubiere."),
                  _c("br"),
                  _vm._v(
                    " b) Gastos de cancelación: conforme las condiciones indicadas en el programa y firmadas en el contrato y según detalle del documento de confirmación de viaje o, en su defecto, el 5% del total del viaje si el desistimiento se produce con más de diez días y menos de quince de antelación a la fecha de comienzo del viaje; el 15% entre los días 3 y 10, y el 25% dentro de las cuarenta y ocho horas anteriores a la salida."
                  ),
                  _c("br"),
                  _vm._v(
                    " De no presentarse a la salida, el cliente no tendrá derecho a devolución alguna de la cantidad desembolsada, abonando, en su caso, las cantidades pendientes, salvo acuerdo entre las partes en otro sentido."
                  ),
                  _c("br"),
                  _vm._v(
                    " En el caso de que alguno de los servicios contratados y anulados estuviera sujeto a condiciones económicas especiales de contratación los gastos de cancelación por desistimiento serán los establecidos por el proveedor de cada servicio. La resolución produce efectos desde el momento en que la voluntad de resolver del cliente llega al conocimiento de SPAIN HERITAGE, debiendo comunicársela por escrito en horario laboral. Conocida la resolución, SPAIN HERITAGE reembolsará al cliente las cantidades que éste hubiese abonado, deducidos los gastos de gestión y, en su caso, los gastos de anulación y las penalizaciones correspondientes."
                  ),
                  _c("br"),
                  _vm._v(
                    " En relación con el seguro contra gastos de cancelación contratado por el viajero, en ningún caso la prima abonada por el mismo será reembolsable."
                  ),
                  _c("br"),
                  _vm._v(
                    " Tendrán derecho a la resolución del contrato y al reembolso total del precio del viaje combinado:"
                  ),
                  _c("br"),
                  _vm._v(
                    " . aquellos viajeros en cuyos viajes contratados concurran circunstancias inevitables y extraordinarias en el lugar de destino, o en las inmediaciones, que afecten de forma significativa a la ejecución al viaje o al transporte de pasajeros al lugar de destino."
                  ),
                  _c("br"),
                  _vm._v(
                    " . si se modifica significativamente alguno de los elementos esenciales del viaje combinado que no sea el precio."
                  ),
                  _c("br"),
                  _vm._v(
                    " . en el supuesto de que el empresario responsable del viaje combinado, lo cancele antes del inicio del viaje, sin que concurran circunstancias extraordinarias, el viajero tendrá derecho además a recibir una compensación."
                  ),
                  _c("br"),
                  _vm._v(
                    " . en caso de no ejecución de los servicios cuando ello afecte sustancialmente a la ejecución del viaje combinado y el organizador o, en su caso, el minorista, no consigan solucionar el problema."
                  ),
                ]),
                _c("h2", [
                  _vm._v(
                    "8.- DERECHOS Y DEBERES DE LAS PARTES DESPUÉS DE INICIAR EL VIAJE"
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "En caso de producirse alguna falta de conformidad en cualquiera de los servicios de viaje contratados, el cliente deberá ponerlo en conocimiento, sin demora indebida, del prestatario de los mismos, corresponsal local o bien directamente a SPAIN HERITAGE. Tras recibir la comunicación estos deberán obrar con diligencia para hallar las soluciones adecuadas que permitan la continuación del viaje."
                  ),
                  _c("br"),
                  _vm._v(
                    " Si cualquiera de los servicios incluidos en el viaje no se ejecuta de conformidad con el contrato, SPAIN HERITAGE deberá subsanar la falta de conformidad, salvo que resulte imposible o si ello entraña un coste desproporcionado, teniendo en cuenta la gravedad de la falta de conformidad y el valor de los servicios de viaje afectados. En caso de que con arreglo a este apartado no se subsane la falta de conformidad será de aplicación la reducción del precio e indemnización de daños y perjuicios."
                  ),
                  _c("br"),
                  _vm._v(
                    " Si SPAIN HERITAGE no subsana la falta de conformidad en un plazo razonable establecido por el viajero, el propio viajero podrá hacerlo y solicitar el reembolso de los gastos necesarios. No será preciso que el viajero especifique un plazo límite si SPAIN HERITAGE se niega a subsanar la falta de conformidad o si se requiere una solución inmediata. Cuando una proporción significativa de los servicios de viaje no pueda prestarse según lo convenido en el contrato de viaje combinado, SPAIN HERITAGE ofrecerá, sin coste adicional alguno para el viajero, fórmulas alternativas adecuadas, de ser posible de calidad equivalente o superior a las especificadas en el contrato, para la continuación del viaje combinado, también cuando el regreso del viajero al lugar de salida no se efectúe según lo acordado. Si las fórmulas alternativas propuestas dan lugar a un viaje combinado de menor calidad que la especificada en el contrato, el organizador o, en su caso, el minorista aplicará al viajero una reducción adecuada del precio. El viajero solo podrá rechazar las fórmulas alternativas propuestas si no son comparables a lo acordado en el contrato de viaje combinado o si la reducción del precio concedida es inadecuada."
                  ),
                  _c("br"),
                  _vm._v(
                    " Cuando una falta de conformidad afecte sustancialmente a la ejecución del viaje y SPAIN HERITAGE no la hayan subsanado en un plazo razonable establecido por el viajero, este podrá poner fin al contrato sin pagar ninguna penalización y solicitar, en su caso, tanto una reducción del precio como una indemnización por los daños y perjuicios causados. Si el viaje combinado incluye el transporte de pasajeros, SPAIN HERITAGE, repatriará además al viajero en un transporte equivalente sin dilaciones indebidas y sin coste adicional."
                  ),
                  _c("br"),
                  _vm._v(
                    " Si es imposible garantizar el retorno del viajero según lo convenido en el contrato debido a circunstancias inevitables y extraordinarias, SPAIN HERITAGE asumirá el coste del alojamiento que sea necesario, de ser posible de categoría equivalente, por un período no superior a tres noches por viajero. Cuando la normativa europea sobre derechos de los pasajeros, aplicable a los correspondientes medios de transporte para el regreso del viajero, establezca períodos más largos, se aplicarán dichos períodos. La limitación de costes a que se refiere el apartado anterior no se aplicará a las personas con discapacidad o movilidad reducida, tal como se definen en el artículo 2.a) del Reglamento (CE) n.º 1107/2006 del Parlamento Europeo y del Consejo, de 5 de julio de 2006, sobre los derechos de las personas con discapacidad o movilidad reducida en el transporte aéreo, ni a sus acompañantes, mujeres embarazadas y menores no acompañados, así como a las personas con necesidad de asistencia médica específica, si sus necesidades particulares han sido participadas al organizador o, en su caso, al minorista al menos cuarenta y ocho horas antes del inicio del viaje. El organizador y el minorista no podrán invocar las circunstancias inevitables y extraordinarias a efectos de la limitación de responsabilidad, si el transportista no puede acogerse a estas circunstancias en virtud de la normativa europea."
                  ),
                  _c("br"),
                  _vm._v(
                    " El viajero deberá atenerse, en todo caso, a las indicaciones que le facilite SPAIN HERITAGE para la adecuada ejecución del viaje, así como a las reglamentaciones que son de general aplicación a los usuarios de los servicios comprendidos en el viaje combinado. En particular, observará una conducta que no perjudique su normal desarrollo. En todo caso, el cliente está obligado a tomar las medidas adecuadas y razonables para intentar reducir los daños que puedan derivarse de la no ejecución o ejecución deficiente del contrato o para evitar que se agraven. Los daños que deriven de no haber adoptado dichas medidas serán de cuenta del viajero y SPAIN HERITAGE no deberá responder por ellos. La infracción grave de estos deberes faculta al organizador para resolver el contrato de contrato de viaje combinado por causa imputable al cliente, respondiendo este último de los daños y perjuicios que se puedan haber irrogado al organizador."
                  ),
                ]),
                _c("h2", [_vm._v("9- RESPONSABILIDAD DE SPAIN HERITAGE")]),
                _c("p", [
                  _vm._v("9.1. Respecto de la Responsabilidad Solidaria"),
                  _c("br"),
                  _vm._v(
                    " SPAIN HERITAGE responderá de forma solidaria frente al viajero del correcto cumplimiento de los servicios de viaje con independencia de que estos servicios los deba ejecutar él mismo u otros prestadores de servicios, y sin perjuicio del derecho de SPAIN HERITAGE de ejercer su derecho de repetición contra dichos prestadores de servicios."
                  ),
                  _c("br"),
                  _vm._v(
                    " 9.2. Respecto del deber de SPAIN HERITAGE de prestar asistencia"
                  ),
                  _c("br"),
                  _vm._v(
                    " SPAIN HERITAGE facilitará unos datos de contacto al viajero para prestarle la necesaria asistencia cuando éste se encuentre en dificultades."
                  ),
                  _c("br"),
                  _vm._v(
                    " SPAIN HERITAGE deberá proporcionar asistencia adecuada y sin demora indebida al viajero en dificultades, en especial en caso de circunstancias inevitables y extraordinarias, en particular mediante el suministro de información adecuada sobre los servicios sanitarios, las autoridades locales y la asistencia consular; la asistencia al viajero para establecer comunicaciones a distancia; y la ayuda para encontrar fórmulas de viaje alternativas. SPAIN HERITAGE podrá facturar un recargo razonable por dicha asistencia si la dificultad se ha originado intencionadamente o por negligencia del viajero. Dicho recargo no superará en ningún caso los costes reales en los que haya incurrido SPAIN HERITAGE."
                  ),
                  _c("br"),
                  _vm._v(
                    " 9.3. Respecto de la responsabilidad por errores en la reserva"
                  ),
                  _c("br"),
                  _vm._v(
                    " SPAIN HERITAGE será responsable de los errores debidos a defectos técnicos que se produzcan en el sistema de reservas que le sean atribuibles, así como de los errores cometidos durante el proceso de reserva, cuando SPAIN HERITAGE haya aceptado gestionar la reserva de un viaje combinado no siendo nunca responsable de los errores de reserva atribuibles al viajero o causados por circunstancias inevitables y extraordinarias."
                  ),
                ]),
                _c("h2", [
                  _vm._v("10.-LÍMITES DE LA RESPONSABILIDAD DE ORGANIZADOR"),
                ]),
                _c("p", [
                  _vm._v(
                    "Cuando las prestaciones del contrato de viaje combinado estén regidas por convenios internacionales, el resarcimiento de los daños corporales y no corporales que resulten del incumplimiento o de la mala ejecución de las mismas estará sujeto a las limitaciones que estos establezcan."
                  ),
                  _c("br"),
                  _vm._v(
                    " Por lo que se refiere a los daños que no sean corporales, éstos deberán ser siempre acreditados por el consumidor. En ningún caso SPAIN HERITAGE se responsabiliza de los gastos de alojamiento, manutención, transportes y otros que se originen por causas de fuerza mayor. Cuando el viaje se efectúe en autocares, “vans”, limusinas y similares contratadas por SPAIN HERITAGE directa o indirectamente, y ocurra un accidente, cualquiera que sea el país donde se produzca, el consumidor habrá de presentar la pertinente reclamación contra la entidad transportista en el respectivo país, a fin de salvaguardar, en su caso, la indemnización del seguro de ésta, siendo auxiliado y asesorado gratuitamente en sus gestiones por SPAIN HERITAGE."
                  ),
                  _c("br"),
                  _vm._v(
                    " La custodia del equipaje no forma parte del contrato de viaje."
                  ),
                  _c("br"),
                  _vm._v(
                    " A todos los efectos y en cuanto al transporte terrestre se refiere, se entenderá que el equipaje y demás enseres personales del usuario los conserva consigo, cualquiera que sea la parte del vehículo en que vayan colocados, y que se transporta por cuenta y riesgo del usuario. Se recomienda a los usuarios que estén presentes en todas las manipulaciones de carga y descarga de los equipajes. Siendo el billete de pasaje el documento vinculante entre las citadas compañías y el pasajero. En el supuesto de sufrir algún daño o extravío el consumidor deberá, presentar, en el acto, la oportuna reclamación a la Compañía de Transportes. SPAIN HERITAGE se compromete a prestar la oportuna asistencia a los clientes que puedan verse afectados por alguna de estas circunstancias."
                  ),
                ]),
                _c("h2", [_vm._v("11.- INFORMACION DOCUMENTAL Y SANITARIA")]),
                _c("p", [
                  _vm._v(
                    "SPAIN HERITAGE informará al cliente sobre las formalidades sanitarias necesarias para el viaje y la estancia, respondiendo de la corrección de la información que facilite."
                  ),
                  _c("br"),
                  _vm._v(
                    " El viajero será el responsable de obtener la documentación necesaria para la realización del viaje, incluido pasaporte, visados y la referida a las formalidades sanitarias debiendo asegurarse antes de iniciar el viaje, de cumplir con todas las normas y requisitos aplicables."
                  ),
                  _c("br"),
                  _vm._v(
                    " Asimismo, es responsabilidad del viajero el pedir una cita en el centro de vacunación internacional más cercano con la debida antelación, siendo estos los únicos competentes en materia sobre la prevención y la seguridad en cuanto al riesgo de contagio de enfermedades propias de terceros países, y los únicos autorizados para hacer cumplir la legislación internacional vigente sobre medicina tropical y preventiva."
                  ),
                  _c("br"),
                  _vm._v(
                    " Los menores de 18 años deben llevar un permiso escrito, firmado por sus padres o tutores, en previsión de que el mismo pueda ser solicitado por cualquier autoridad."
                  ),
                  _c("br"),
                  _vm._v(
                    " Todos los daños que puedan derivarse de la falta de esa documentación serán por cuenta del viajero y, en particular, los gastos producidos por la interrupción del viaje y su eventual repatriación."
                  ),
                ]),
                _c("h2", [_vm._v("12.- SEGUROS")]),
                _c("p", [
                  _vm._v(
                    "SPAIN HERITAGE informa al consumidor sobre la posibilidad de suscripción facultativa de un seguro que le cubra los gastos de cancelación y/o de asistencia en caso de pérdida o deterioro de equipaje, accidente, enfermedad, repatriación o fallecimiento."
                  ),
                ]),
                _c("h2", [
                  _vm._v(
                    "13.-RECLAMACIONES, ACCIONES JUDICIALES Y PRESCRIPCIÓN"
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "Las presentes Condiciones Generales estarán sometidas a la legislación española. Las partes se someten, a su elección, para la resolución de los conflictos y con renuncia a cualquier otro fuero, a los juzgados y tribunales del domicilio del usuario."
                  ),
                  _c("br"),
                  _vm._v(
                    " El cliente podrá reclamar en vía judicial ante los tribunales del lugar donde tenga su domicilio para resolver las discrepancias o reclamaciones que susciten la interpretación o la ejecución del contrato de viaje combinado y de estas CONDICIONES GENERALES."
                  ),
                  _c("br"),
                  _vm._v(
                    " Las acciones derivadas de los derechos reconocidos en la Ley en relación con el contrato de viaje combinado prescriben por el transcurso del plazo de dos años desde el día de celebración del contrato. En todo caso, y con pleno respeto a lo dispuesto en el apartado precedente, SPAIN HERITAGE pone a disposición de los clientes la siguiente información para que puedan interponer sus quejas y reclamaciones o solicitar información sobre el viaje contratado:"
                  ),
                  _c("br"),
                  _vm._v(
                    " Los usuarios, en caso de discrepancias o incidencias con los servicios contratados, podrán dirigirse a través de email a la agencia de viajes a la siguiente dirección: info@spainheritagenetwork.com, o a través de correo postal a SPAIN HERITAGE TOURS S.L. Rodríguez Marín 61 – Bajo Izquierda, 28016 - Madrid. En virtud de la normativa de consumidores y usuarios se informa que SPAIN HERITAGE no se encuentra adherido a ningún sistema arbitral de consumo, si bien y en cumplimiento de las disposiciones de La Comisión Europea Reglamento (UE) n.º 524/2013 proporcionamos la información sobre la plataforma RLL y dirección de acceso: https://ec.europa.eu/consumers/odr"
                  ),
                ]),
                _c("h2", [_vm._v("14.- PROTECCIÓN DE DATOS")]),
                _c("p", [
                  _vm._v(
                    "En cumplimiento de la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de derechos digitales y del Reglamento Europeo 2016/679, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos, la agencia y el viajero se comprometen a respetar y cumplir con la normativa vigente en dicha materia. El usuario al acceder, navegar y/o usar el Sitio Web consiente el tratamiento de dicha información y datos y declara que toda la información o datos que facilita son veraces."
                  ),
                  _c("br"),
                  _vm._v(
                    " Con la adhesión a las presentes Condiciones Generales por su aceptación expresa indicada en la solicitud del viaje por vía telemática y la aceptación de las Condiciones Particulares, se presta el consentimiento expreso a que los datos personales que se proporcionen serán tratados como responsable por SPAIN HERITAGE. Las finalidades del tratamiento serán gestionar adecuadamente la reserva y la adecuada prestación de los servicios contratados, así como poder hacer llegar información sobre productos, servicios, ofertas y promociones relativas tanto al programa como a acciones ligadas a la actividad de SPAIN HERITAGE, en el ámbito del turismo, la hostelería, la restauración y los viajes. Los datos sólo serán cedidos, única y exclusivamente, a terceros que intervengan de forma necesaria en la prestación de servicios vinculados al objeto del viaje contratado y con idénticas finalidades a las anteriores, ellos con salvaguardia del deber de secreto y aplicación de las medidas de seguridad exigidas por la normativa en vigor. Se podrán ejercer los derechos de acceso, rectificación, cancelación u oposición a su tratamiento, por medio de escrito acompañado de copia del Documento Nacional de Identidad o pasaporte en vigor y dirigido por correo a la anterior dirección o por correo electrónico (protecciondedatos@spainheritagenetwork.com ), a la atención del responsable de Protección de Datos. En caso de que efectivamente llegue a contratar con la agencia, la información relativa a protección de datos se proporciona al usuario y se encuentra a su disposición a través de nuestra política de privacidad y protección de datos en www.spainheritagenetwork.com"
                  ),
                ]),
                _c("h2", [_vm._v("15.- Vigencia")]),
                _c("p", [
                  _vm._v(
                    "SPAIN HERITAGE se reserva el derecho a modificar, limitar o cancelar estas Condiciones Generales en cualquier momento. En cualquier caso serán de aplicación las condiciones que estuviesen en vigor cuando el cliente formalizó la reserva, o las posteriores si éstas fuesen más favorables para los intereses del consumidor."
                  ),
                ]),
                _c("p", [_vm._v(" ")]),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }