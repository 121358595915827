<template>
    <default-layout>
        <div class="spainhn-solid-red-background d-flex" style="min-height: 5vh; width:100%">
            <div class="d-flex ps-5 my-auto container">
                <p class="my-auto pb-0 mb-0 spainhn-small-text" role="button" @click="goToCastillosYPalacios">Spain Heritage Network >> </p>
                <p class="my-auto pb-0 mb-0 ms-2 spainhn-small-text">{{$t('default.FOOTER_SELL_GENERALS')}} </p>
            </div>
        </div>
        <div class="container-fluid px-xl-5 px-1 general-main">
            <div class="container-fluid mb-5">
                <div class="row text-start margen-estandard px-xl-5">
                    <div class="d-flex flex-column pt-3 col-12 px-xl-5">     
                        <h5 class="mb-0"><strong><small>{{tipoHeader}} {{monumentoNombre}}</small></strong></h5>
                        <p class="mb-0"><strong><small>{{tipoSubheader}} {{monumentoNombre}}</small></strong></p>

                        <div class="h-100 pt-5 ps-3 pe-0 spainhn-text d-flex flex-column" style="border-radius:10px;">
                            <h1>CONDICIONES GENERALES DE CONTRATACI&Oacute;N</h1>

<p>Las presentes Condiciones Generales de Contrataci&oacute;n se rigen por lo dispuesto en el Real Decreto Legislativo 1/2007, de 16 de noviembre, de la Ley General para la Defensa de los Consumidores y Usuarios y otras leyes complementarias, modificado por el Real Decreto-ley 23/2018, de 21 de diciembre, de trasposici&oacute;n de directivas en materia de marcas, transporte ferroviario y viajes combinados y servicios de viajes vinculados, por la Ley 7/1998, de 13 de abril, sobre condiciones generales de contrataci&oacute;n y por lo dispuesto a continuaci&oacute;n y Ley 34/2002, de 11 de julio, de servicios de la sociedad de la informaci&oacute;n y de comercio electr&oacute;nico.</p>

<p>&nbsp;</p>

<p>Cualquier contrataci&oacute;n con SPAIN HERITAGE est&aacute; sometida a las presentes condiciones generales de venta que a continuaci&oacute;n se publicitan libremente, con el fin de que cada CLIENTE pueda conocer y verificar la compra y los t&eacute;rminos espec&iacute;ficos.</p>

<p>&nbsp;</p>

<p>&nbsp;</p>

<p>El acceso, la navegaci&oacute;n y uso del Sitio Web, confiere la condici&oacute;n de usuario (en adelante referido, indistintamente, individualmente como Usuario o conjuntamente como Usuarios), por lo que se aceptan, desde que se inicia la navegaci&oacute;n por el Sitio Web, todas las Condiciones aqu&iacute; establecidas, as&iacute; como sus ulteriores modificaciones, sin perjuicio de la aplicaci&oacute;n de la correspondiente normativa legal de obligado cumplimiento seg&uacute;n el caso.</p>

<p>&nbsp;</p>

<p>&nbsp;</p>

<p>Las CONDICIONES GENERALES tienen por objeto regular los t&eacute;rminos contractuales para la prestaci&oacute;n de los SERVICIOS por parte de SPAIN HERITAGE, y, en su caso, la contraprestaci&oacute;n debida por el CLIENTE a SPAIN HERITAGE, por la utilizaci&oacute;n por aqu&eacute;l de determinados SERVICIOS. La prestaci&oacute;n de los SERVICIOS se llevar&aacute; a cabo a mediante la obtenci&oacute;n, disposici&oacute;n, organizaci&oacute;n, empleo y gesti&oacute;n por parte de SPAIN HERITAGE de los recursos t&eacute;cnicos, humanos y operativos necesarios al efecto y, siempre y en todo caso, como contraprestaci&oacute;n a los precios vigentes en cada momento cuando los SERVICIOS sean de pago. La mera navegaci&oacute;n por la web https://www.spainheritagenetwork.com no tendr&aacute; la consideraci&oacute;n de SERVICIO.</p>

<p>&nbsp;</p>

<p>&nbsp;</p>

<p>SPAIN HERITAGE vela y protege especialmente al viajero en los t&eacute;rminos que se detallan en las presentes CONDICIONES GENERALES, as&iacute; como en el AVISO LEGAL y POL&Iacute;TICA DE PRIVACIDAD.</p>

<p>&nbsp;</p>

<p>&nbsp;</p>

<p>La informaci&oacute;n contenida en las presentes CONDICIONES GENERALES de viajes combinados y aquellas que se facilitan en el momento precontractual, tienen car&aacute;cter gen&eacute;rico. No son de esperar variaciones significativas en el contenido y ser&aacute;n modificadas exclusivamente en los t&eacute;rminos que se recojan en las CONDICIONES PARTICULARES y en determinados supuestos que se exponen m&aacute;s adelante.</p>

<p>&nbsp;</p>

<p>&nbsp;</p>

<p>PROCESO DE COMPRA O ADQUISICI&Oacute;N</p>

<p>&nbsp;</p>

<p>&nbsp;</p>

<p>Los Usuarios debidamente registrados pueden comprar en el Sitio Web por los medios y formas establecidos. Deber&aacute;n seguir el procedimiento de compra y/o adquisici&oacute;n online de SPAIN HERITAGE, durante el cual varios productos y/o servicios pueden ser seleccionados y a&ntilde;adidos al espacio final de compra y, finalmente, hacer clic en &ldquo;Comprar&rdquo;. Seguidamente, el Usuario recibir&aacute; un correo electr&oacute;nico confirmando que SPAIN HERITAGE ha recibido su pedido o solicitud de compra y/o prestaci&oacute;n del servicio, es decir, la confirmaci&oacute;n del pedido. Y, en su caso, se le informar&aacute;, igualmente, mediante correo electr&oacute;nico cuando su compra este pendiente de confirmaci&oacute;n por el proveedor o las variaciones en relaci&oacute;n a la respuesta obtenida de &eacute;stos.</p>

<p>&nbsp;</p>

<p>&nbsp;</p>

<p>DISPONIBILIDAD Y RESERVA</p>

<p>&nbsp;</p>

<p>&nbsp;</p>

<p>Todos los pedidos de compra recibidos a trav&eacute;s del Sitio Web est&aacute;n sujetos a la disponibilidad de los prestadores de servicios, actuando SPAIN HERITAGE en relaci&oacute;n a los servicios sueltos como mero intermediario, y en relaci&oacute;n a los paquetes tur&iacute;sticos (viaje combinado) act&uacute;a como organizador de los mismos.</p>

<p>&nbsp;</p>

<p>&nbsp;</p>

<p>La compra de cualquier producto s&oacute;lo ser&aacute; efectiva en el momento en que SPAIN HERITAGE realice el cargo del importe total del viaje de manera v&aacute;lida en la tarjeta de cr&eacute;dito suministrada por el Usuario o justificante de la transferencia, pudiendo hasta dicho momento ser cancelada por parte SPAIN HERITAGE sin que el usuario tenga derecho a reclamaci&oacute;n alguna.</p>

<p>&nbsp;</p>

<p>&nbsp;</p>

<p>La solicitud de una reserva implica el compromiso por parte del cliente de autorizar el cargo en la tarjeta de cr&eacute;dito que suministra. En el caso de que no fuera posible realizar el cargo en la tarjeta de cr&eacute;dito, por circunstancias ajenas a la gesti&oacute;n de SPAIN HERITAGE el Usuario acepta que SPAIN HERITAGE no emitir&aacute; la reserva efectuada.</p>

<p>&nbsp;</p>

<p>MEDIOS T&Eacute;CNICOS PARA CORREGIR ERRORES</p>

<p>Se pone en conocimiento del Usuario que en caso de que detecte que se ha producido un error al introducir datos necesarios para procesar su solicitud de compra en el Sitio Web, podr&aacute; modificar los mismos poni&eacute;ndose en contacto con SPAIN HERITAGE a trav&eacute;s de los espacios de contacto habilitados en el Sitio Web, y, en su caso, a trav&eacute;s de aquellos habilitados para contactar con el servicio de atenci&oacute;n al cliente, info@spainheritagenetwork.com y/o utilizando los datos de contacto facilitados en el aviso legal.</p>

<p>En cualquier caso, el Usuario, antes de hacer clic en &ldquo;confirmar compra&rdquo;, tiene acceso al espacio, donde se va anotando su solicitud de compra, donde podr&aacute; revisar y en su caso realizar modificaciones.</p>

<p>DERECHO DE DESISTIMIENTO</p>

<p>Es la facultad que tiene el usuario de dejar sin efecto un contrato en determinados casos sin necesidad de justificar su decisi&oacute;n ni asumir penalizaci&oacute;n alguna.</p>

<p>En virtud del art&iacute;culo 103 apartado l) del Real Decreto Legislativo 1/2007, de 16 de noviembre, por el que se aprueba el texto refundido de la Ley General para la Defensa de los Consumidores y Usuarios y otras leyes complementarias, no ser&aacute; aplicable el derecho de desistimiento a los contratos que se refieran a suministro de servicios de alojamiento para fines distintos del de servir de vivienda, transporte de bienes, alquiler de veh&iacute;culos, comida o servicios relacionados con actividades de esparcimiento, si los contratos prev&eacute;n una fecha o un periodo de ejecuci&oacute;n espec&iacute;ficos.</p>

<p>En cuanto al transporte de personas, seg&uacute;n el art&iacute;culo 93 k) del Real Decreto Legislativo 1/2007 por el que se aprueba el Texto Refundido de la Ley General para la Defensa de los Consumidores y Usuarios la regulaci&oacute;n de las ventas a distancia (a excepci&oacute;n del art&iacute;culo 98.2) no resulta de aplicaci&oacute;n a los contratos de servicio de transporte de pasajeros. Por lo que en las reservas de servicios de transporte de personas no resulta de aplicaci&oacute;n el derecho de desistimiento. De este modo, en el caso de que el Usuario manifieste su voluntad de cancelar los billetes contratados le ser&aacute;n de aplicaci&oacute;n las condiciones de cancelaci&oacute;n de la compa&ntilde;&iacute;a de que se trate.</p>

<h2>1.- DEFINICIONES</h2>

<p>A los efectos de las presentes CONDICIONES GENERALES se entiende por:</p>

<p>Servicio suelto: adquisici&oacute;n de un servicio, sin combinaci&oacute;n con otros, ni en el porcentaje estipulado en su normativa para ser considerado viaje combinado o vinculado. Ser&aacute;n considerado un servicio suelto, con car&aacute;cter enunciativo y no limitativo la adquisici&oacute;n de la entrada a los monumentos, el alojamiento tur&iacute;stico, y cualquier otro servicio que se ofrezca de manera independiente y sin paquetizar. Los servicios sueltos est&aacute;n sometidos a sus condiciones particulares de venta cuya informaci&oacute;n se facilita desde el portal.</p>

<p>Viaje combinado: es aquel en el que se combinan, al menos, dos servicios de viaje a efectos del mismo viaje o vacaci&oacute;n, siempre que la combinaci&oacute;n se formalice en un &uacute;nico contrato con independencia de que lo solicite o no el viajero. Tambi&eacute;n se considera viaje combinado aquel que, a pesar de formalizarse en varios contratos, Se realice en uno de nuestros puntos de venta.</p>

<p>Se seleccione la combinaci&oacute;n de servicios de viaje antes de que el viajero consienta pagar. Se ofrezca, se venda o se facture por el organizador, o en su caso el minorista, a un precio a tanto alzado o global. Se anuncie por el organizador, o en su caso el minorista, como &ldquo;viaje combinado&rdquo;.</p>

<p>Dichos contratos faculten al viajero a elegir entre una selecci&oacute;n de distintos servicios de viaje. Por &uacute;ltimo, tambi&eacute;n son contratos de viaje combinado, aquellos contratos formalizados en https://www.spainheritagenetwork.com (a trav&eacute;s de un proceso de reserva en l&iacute;nea conectado), en el que el viajero celebra un &uacute;nico contrato con el empresario, pero varios con los prestadores de los servicios contratados por el viajero a m&aacute;s tardar dentro de las 24 horas siguientes a la confirmaci&oacute;n de la &uacute;ltima reserva. Tambi&eacute;n se considera viaje combinado aquel que combine un servicio de viaje (transporte, alojamiento y alquiler de veh&iacute;culo) y adicionalmente, uno o m&aacute;s servicios de tur&iacute;sticos, siempre que: Los servicios tur&iacute;sticos representen una proporci&oacute;n igual o superior al 25% del valor de la combinaci&oacute;n y se anuncien como una caracter&iacute;stica esencial de la combinaci&oacute;n o; Si los servicios tur&iacute;sticos solo han sido contratados despu&eacute;s de que se haya iniciado la ejecuci&oacute;n de 1 servicio de viaje (transporte, alojamiento y alquiler de veh&iacute;culo).<br />
&bull; Servicio de viaje: Son servicios de viaje, el transporte de pasajeros, el alojamiento cuando no sea parte integrante del transporte de pasajeros y no tenga un fin residencial, el alquiler de turismos (Real Decreto 750/2010, de 4 de junio) y, cualquier otro servicio tur&iacute;stico que no forme parte integrante de un servicio de viaje de los citados anteriormente.<br />
&bull; Contrato de Viaje Combinado: es aquel que se formaliza en un solo contrato por el total del conjunto del viaje o, la formalizaci&oacute;n de varios contratos por cada uno de los servicios de viaje incluidos en el viaje combinado ofertado.<br />
&bull; Fecha de inicio del viaje combinado: es aquel d&iacute;a en el que comienza la ejecuci&oacute;n de los servicios de viaje incluidos en el contrato.<br />
&bull; Repatriaci&oacute;n: el regreso del viajero al lugar de salida o a cualquier otro lugar acordado por las partes contratantes.<br />
&bull; Falta de conformidad: la no ejecuci&oacute;n o la ejecuci&oacute;n incorrecta de los servicios de viaje incluidos en un viaje combinado.<br />
&bull; Viajero: toda persona que tiene intenci&oacute;n de celebrar un contrato o tiene derecho a viajar en virtud de un contrato.<br />
&bull; Empresario: aquel que atiende a los viajeros de manera presencial o en l&iacute;nea, tanto si act&uacute;a como organizador, minorista, empresario que facilita servicios de viaje o como prestador de servicios de viajes.<br />
&bull; Organizador: se considera a aquel empresario que combina y vende u oferta viajes combinados directamente, a trav&eacute;s de o junto con otro empresario.<br />
&bull; Minorista: aquel empresario distinto del organizador que vende u oferta los viajes combinados por un organizador.<br />
&bull; Menor: toda persona menor de dieciocho a&ntilde;os de edad.<br />
&bull; Circunstancias inevitables y extraordinarias: se considerar&aacute;n aquellas situaciones que est&aacute;n fuera de control de la parte que alega la situaci&oacute;n y cuyas consecuencias no habr&iacute;an podido evitarse incluso si se hubieran adoptado todas las medidas razonables.<br />
&bull; Punto de venta: hace referencia a toda instalaci&oacute;n de venta al por menor, tanto muebles como inmueble, o a un sitio web de venta al por menor o a un dispositivo similar de venta minorista en l&iacute;nea, incluso cuando estos sitios web o dispositivos se presenten a los viajeros como un dispositivo &uacute;nico, incluido un servicio telef&oacute;nico.<br />
&bull; Establecimiento: el acceso a una actividad econ&oacute;mica no asalariada y su ejercicio, as&iacute; como la constituci&oacute;n y gesti&oacute;n de empresas y especialmente de sociedades, en las condiciones fijadas por la legislaci&oacute;n, por una duraci&oacute;n indeterminada, en particular por medio de una infraestructura estable. Informaci&oacute;n precontractual: Se entiende por informaci&oacute;n precontractual, aquella que el organizador o, en su caso, el minorista est&aacute; obligado a facilitar al viajero antes de que el viajero quede obligado por cualquier contrato u oferta.</p>

<h2>2. ORGANIZACI&Oacute;N DEL VIAJE COMBINADO</h2>

<p>La organizaci&oacute;n de los viajes combinados se realiza por la agencia mayorista, o mayorista-minorista en su caso, detallada en la informaci&oacute;n precontractual facilitada al cliente y en el contrato de viaje combinado.</p>

<h2>3. PROCESO DE CONTRATACI&Oacute;N DE UN VIAJE COMBINADO</h2>

<p>SPAIN HERITAGE ofrecer&aacute; al viajero interesado un programa (informaci&oacute;n precontractual) que contendr&aacute; la descripci&oacute;n del viaje combinado de acuerdo con el contenido del art&iacute;culo 153 del Real Decreto Legislativo 1/2007. El consumidor que acepte el programa ofertado suscribir&aacute; con el organizador un contrato de viaje combinado de acuerdo con el contenido del art&iacute;culo 155 del Real Decreto Legislativo 1/2007 y tras el pago por parte del primero del importe establecido en el mismo. Formar&aacute;n parte integrante del contrato de viaje combinado, todas las condiciones que regulan el viaje combinado contratado, en concreto, las CONDICIONES GENERALES dispuestas en este texto, las condiciones del programa, y las condiciones particulares pactadas entre las partes. SPAIN HERITAGE se compromete a entregar al viajero una copia del contrato de viaje combinado que formalicen las partes, a m&aacute;s tardar en el plazo de 24 horas a contar desde dicha formalizaci&oacute;n. SPAIN HERITAGE realizar&aacute; las gestiones oportunas para obtener de cada proveedor la confirmaci&oacute;n de los servicios de viaje.<br />
En el supuesto de que alguno de estos servicios de viaje no pudiera ser confirmado por falta de disponibilidad, se ofrecer&aacute; al viajero en su lugar otro de caracter&iacute;sticas similares, expresado el nuevo precio, que el cliente podr&aacute; aceptar o rechazar.<br />
En el caso de que el cliente rechace el cambio propuesto, SPAIN HERITAGE reembolsar&aacute; los importes depositados a la fecha y en el caso de que el cliente acepte el cambio propuesto &eacute;ste se reflejar&aacute; en un Anexo que se incorporar&aacute; al contrato de viaje combinado inicialmente firmado entre las partes.</p>

<h2>4.- DELIMITACI&Oacute;N DE LOS SERVICIOS</h2>

<p><br />
4.1.- Transportes<br />
El cliente debe presentarse en el lugar indicado el d&iacute;a de la fecha de inicio de viaje combinado con la antelaci&oacute;n prevista en la documentaci&oacute;n de viaje facilitada. La presentaci&oacute;n en el punto de salida del transporte contratado se efectuar&aacute; con un m&iacute;nimo de antelaci&oacute;n de 1 horas y en todo caso se seguir&aacute;n estrictamente las recomendaciones espec&iacute;ficas que indique la documentaci&oacute;n del viaje facilitada al suscribir el contrato. Si el consumidor no pudiera realizar el viaje por no haberse presentado con la antelaci&oacute;n requerida, se aplicar&aacute; el r&eacute;gimen previsto en lo relativo a la falta de presentaci&oacute;n a la salida por parte del cliente. Las reservas se realizar&aacute;n con los datos indicados por el cliente. Los datos err&oacute;neamente facilitados por los pasajeros pueden ser constitutivo de denegaci&oacute;n por parte del prestador del servicio de la prestaci&oacute;n del mismo, siendo responsable el viajero de las consecuencias que se deriven del mismo.<br />
Los errores en la reserva imputables al cliente debido a un error en los datos o a una informaci&oacute;n proporcionada que sea incompleta, en ning&uacute;n caso ser&aacute; responsabilidad de SPAIN HERITAGE La p&eacute;rdida o da&ntilde;o que se produzca en relaci&oacute;n con el equipaje u otros objetos que el cliente lleve consigo, y conserve bajo su custodia, son de su exclusiva cuenta y riesgo o, en su caso, de la de la compa&ntilde;&iacute;a prestadora de los servicios de viaje de transporte.<br />
4.2.- Alojamiento<br />
4.2.1.- General<br />
La calidad y contenido de los servicios prestados por el alojamiento tur&iacute;stico vendr&aacute; determinada por la categor&iacute;a tur&iacute;stica oficial, si la hubiere, asignada por el &oacute;rgano competente del pa&iacute;s en el que se encuentre. Las habitaciones triples y/o cu&aacute;druples son generalmente habitaciones dobles a las que se a&ntilde;ade una o dos camas, que suelen ser un sof&aacute;-cama o una cama supletoria (plegat&iacute;n), excepto en ciertos establecimientos donde en lugar de camas adicionales existen dos camas de tama&ntilde;o superior al est&aacute;ndar debiendo pernoctar dos personas en cada una de ellas. El horario habitual para la entrada y salida en los alojamientos tur&iacute;sticos est&aacute; determinado en funci&oacute;n del primer y &uacute;ltimo servicio que el cliente vaya a utilizar. Como norma general, y salvo que expresamente se pacte otra cosa, las habitaciones podr&aacute;n ser utilizadas a partir de las 15 horas del d&iacute;a de llegada y deber&aacute;n quedar libres antes de las 12 horas del d&iacute;a de salida, con independencia de la hora en que est&eacute; prevista la llegada al hotel o de la hora en que est&eacute; prevista la continuaci&oacute;n del viaje.<br />
Cuando el usuario prevea su llegada al alojamiento reservado en fechas u horas distintas a las rese&ntilde;adas, es conveniente, para evitar problemas y malas interpretaciones, comunicar con la mayor anticipaci&oacute;n posible tal circunstancia al organizador y al alojamiento tur&iacute;stico directamente, seg&uacute;n el caso. Igualmente, debe el usuario consultar al organizador, en el momento de hacer la reserva, la posibilidad de llevar animales dom&eacute;sticos, pues generalmente no son admitidos en los alojamientos tur&iacute;sticos. En el supuesto de haber confirmado la admisi&oacute;n de animales dom&eacute;sticos y se pretenda viajar con ellos, tal circunstancia deber&aacute; ser comunicada por parte del cliente a SPAIN HERITAGE para hacerse constar tal circunstancia en el contrato.<br />
El servicio de alojamiento tur&iacute;stico implicar&aacute; que la habitaci&oacute;n est&eacute; disponible en la noche correspondiente, entendi&eacute;ndose prestado con independencia de que, por circunstancias propias del viaje, el horario de entrada en el mismo se produzca m&aacute;s tarde de lo inicialmente previsto.<br />
<br />
4.2.2.-Servicios Suplementarios<br />
Cuando los viajeros soliciten servicios suplementarios (por ejemplo habitaci&oacute;n vista al mar, etc.) que no puedan ser confirmados con seguridad por SPAIN HERITAGE, el viajero podr&aacute; optar por desistir, por escrito, definitivamente del servicio suplementario solicitado o mantener su solicitud a la espera de que tales servicios suplementarios puedan finalmente serle prestados si&eacute;ndole confirmados a su llegada al alojamiento tur&iacute;stico.<br />
En el supuesto de que las partes hubieran convenido el pago previo del precio de los servicios suplementarios y que estos finalmente no pudieran ser prestados, el importe abonado ser&aacute; reembolsado por SPAIN HERITAGE en los plazos legalmente establecidos, bien al desistimiento del servicio por parte del consumidor o al regreso del viaje, seg&uacute;n el caso.<br />
<br />
4.3.- R&eacute;gimen alimenticio<br />
Por defecto, y salvo que se especifique lo contrario en el programa y en el contrato, el r&eacute;gimen alimenticio de los alojamientos contratados es de &ldquo;alojamiento y desayuno&rdquo;.<br />
El r&eacute;gimen de media pensi&oacute;n, salvo que se indique cosa en contrario, incluye desayuno continental y cena. Por regla general, y a menos que se indique expresamente lo contrario, dichas cenas no incluyen el precio de las bebidas. Las dietas especiales (vegetarianas o de reg&iacute;menes especiales) s&oacute;lo se garantizan si han sido avisadas antes del inicio del viaje por parte del viajero a SPAIN HERITAGE y se ha confirmado la disponibilidad por parte del prestador del servicio.<br />
Si por causa de retraso, sea cual fuere, se perdiera alg&uacute;n servicio de restauraci&oacute;n correspondiente a la pensi&oacute;n alimenticia contratada, no existir&aacute; derecho a devoluci&oacute;n alguna.<br />
4.4.- Condiciones econ&oacute;micas especiales para ni&ntilde;os<br />
Dada la diversidad de las condiciones aplicables a los viajeros menores de edad, dependiendo del proveedor de servicios y de la fecha del viaje, es obligatorio que el viajero informe de la edad de los miembros del grupo y consulte siempre el alcance de las condiciones especiales aplicables que existan y que en cada momento ser&aacute;n objeto de informaci&oacute;n concreta y detallada. Estas cuestiones se recoger&aacute;n en el contrato y/o en la documentaci&oacute;n del viaje que se entregue.</p>

<h2>5.- SERVICIOS EN VIAJE COMBINADO</h2>

<p>5.1. El precio del viaje<br />
El precio del viaje incluye:<br />
<br />
&bull; El precio de todos los servicios de viaje contratados (transporte de pasajeros, alojamiento, alquiler de veh&iacute;culos de motor, etc.), con independencia de si se ha formalizado en un contrato o en varios cuando dichos servicios est&eacute;n incluidos en el programa contratado con las especificidades que consten en el mismo;<br />
. Las comisiones recargos y costes adicionales, salvo que se indique lo contrario en el contrato de viaje combinado;<br />
. Las tasas (o impuestos indirectos cuando sean aplicables), salvo que se indique lo contrario en el contrato de viaje combinado;<br />
. Los gastos de gesti&oacute;n;<br />
. La prestaci&oacute;n de asistencia por parte de SPAIN HERITAGE y/o de los proveedores de servicios de viaje en destino si el viajero se halla en dificultades, especialmente en supuestos de circunstancias extraordinarias e inevitables y las dispuestas en la normativa de aplicaci&oacute;n;<br />
. La posibilidad de solicitar asistencia cuando tenga dificultades para presentar una reclamaci&oacute;n por cualquier falta de conformidad del viajero durante el viaje.<br />
El precio del viaje no incluye:<br />
Todo aquello que no est&eacute; expresamente recogido en el contrato de viaje como visados, certificados de vacunaci&oacute;n, tributos y/o tasas tur&iacute;sticas de entrada y/o salidas, extras (caf&eacute;s, vinos, licores, aguas minerales, reg&iacute;menes alimenticios especiales salvo que se haya indicado en el contrato de viaje combinado, lavado y planchado de ropa, parking, utilizaci&oacute;n del tel&eacute;fono, cunas, alquiler de TV, servicios de Spa, balnearios, termas, masajes, tratamientos m&eacute;dicos, terap&eacute;uticos o de salud) y cualquier otro servicio similar que el alojamiento tur&iacute;stico ofrezca por un precio independiente al del servicio de alojamiento contratado, as&iacute; como aquellos impuestos adicionales en determinados destinos, que pueden variar en funci&oacute;n de la categor&iacute;a del establecimiento y del destino.<br />
Las tasas tur&iacute;sticas se abonar&aacute;n directamente en el establecimiento hotelero y ser&aacute; el viajero el &uacute;nico responsable de informarse sobre la existencia de las mismas y de su abono y cualesquiera otros an&aacute;logos.<br />
En el caso de excursiones o visitas facultativas no contratadas en origen, &eacute;stas no forman parte del contrato de viaje no pudiendo ser responsable SPAIN HERITAGE de la correcta prestaci&oacute;n de las mismas. De no contratarse en origen, su propuesta tiene mero car&aacute;cter informativo y por ello, en el momento de contratarse en destino, pueden producirse variaciones en sus costes que alteren el precio estimado. Dichas excursiones o visitas facultativas ser&aacute;n informadas al cliente con sus condiciones estimadas no pudiendo garantizar SPAIN HERITAGE la posible prestaci&oacute;n por parte de terceros de las mismas.<br />
Dentro del precio del viaje combinado no est&aacute;n incluidas las propinas.<br />
5.2. Modificaciones del precio<br />
El precio del viaje ha sido calculado en base a los tipos de cambio, tarifas de transporte, coste del carburante. Debido a la constante subida del petr&oacute;leo o en otras fuentes de energ&iacute;a, en ocasiones, el precio del viaje podr&aacute; ser modificado posteriormente a la contrataci&oacute;n y confirmaci&oacute;n del viaje, por SPAIN HERITAGE siempre que dicha facultad se disponga en el contrato formalizado.<br />
Dependiendo del destino se impondr&aacute;n al viajero, como parte integrante del precio total del viaje combinado, las tasas, impuestos y recargos tur&iacute;sticos Dichos importes podr&aacute;n ser modificados por aquellos terceros que no est&aacute;n involucrados directamente en la ejecuci&oacute;n del viaje combinado y, como consecuencia de ello, podr&aacute;n ser modificados por SPAIN HERITAGE.<br />
Toda modificaci&oacute;n en el precio podr&aacute; ser revisada, tanto al alza como a la baja, en los supuestos en que haya habido modificaci&oacute;n del precio de los transportes, incluido el coste del carburante, las tasas e impuestos relativos a determinados servicios contratados y los cambios en el tipo de divisa aplicables. Dicha revisi&oacute;n solo podr&aacute; efectuarse con una antelaci&oacute;n a m&aacute;s tardar veinte d&iacute;as naturales antes del inicio del viaje combinado, debiendo ser &eacute;sta comunicada al viajero con la justificaci&oacute;n del incremento y su c&aacute;lculo en soporte duradero.<br />
El viajero tendr&aacute; derecho a una reducci&oacute;n del precio correspondiente a toda disminuci&oacute;n de los costes mencionados en este apartado que se produzcan entre la fecha de confirmaci&oacute;n de reserva de viaje combinado y fecha de inicio del mismo. En caso de reducci&oacute;n del precio, SPAIN HERITAGE tendr&aacute; derecho a deducir los gastos administrativos reales del reembolso debido al viajero.<br />
El viajero podr&aacute; resolver el contrato sin penalizaci&oacute;n alguna si la revisi&oacute;n significara una modificaci&oacute;n superior al 8% del precio total del viaje.<br />
5.3. Reducci&oacute;n del precio e indemnizaci&oacute;n por da&ntilde;os y perjuicios<br />
El viajero tendr&aacute; derecho a una reducci&oacute;n del precio adecuada por cualquier periodo durante el cual haya habido falta de conformidad, a menos que SPAIN HERITAGE demuestre que la falta de conformidad es imputable al viajero. Asimismo, el viajero tendr&aacute; derecho a recibir una indemnizaci&oacute;n adecuada de SPAIN HERITAGE por cualquier da&ntilde;o o perjuicio que sufra como consecuencia de cualquier falta de conformidad. La indemnizaci&oacute;n se abonar&aacute; sin demora indebida. El viajero no tendr&aacute; derecho a una indemnizaci&oacute;n por da&ntilde;os y perjuicios si SPAIN HERITAGE demuestra que la falta de conformidad es imputable al viajero, imputable a un tercero ajeno a la prestaci&oacute;n de los servicios contratados e imprevisible o inevitable, o debida a circunstancias inevitables y extraordinarias.<br />
En la medida en que los convenios internacionales que vinculan a la Uni&oacute;n Europea limiten el alcance o las condiciones del pago de indemnizaciones por parte de prestadores de servicios de viaje incluidos en un viaje combinado, las mismas limitaciones se aplicar&aacute;n SPAIN HERITAGE En los dem&aacute;s casos, el contrato podr&aacute; limitar la indemnizaci&oacute;n que debe pagar SPAIN HERITAGE siempre que esa limitaci&oacute;n no se aplique a los da&ntilde;os corporales o perjuicios causados de forma intencionada o por negligencia y que su importe no sea inferior al triple del precio total del viaje. Todo derecho a indemnizaci&oacute;n o reducci&oacute;n del precio en virtud de lo establecido en la ley de aplicaci&oacute;n no afectar&aacute; a los derechos de los viajeros contemplados en la legislaci&oacute;n europea y los viajeros tendr&aacute;n derecho a presentar reclamaciones con arreglo a ley aplicable, a dichos reglamentos y a los convenios internacionales. La indemnizaci&oacute;n o reducci&oacute;n del precio concedida en virtud de la ley y la concedida en virtud de dichos reglamentos y convenios internacionales, se deducir&aacute;n la una de la otra para evitar el exceso de indemnizaci&oacute;n.<br />
5.4. Forma de pago<br />
En el momento de la firma del contrato el viajero deber&aacute; abonar al organizador un anticipo que en ning&uacute;n caso ser&aacute; superior al 40% de la suma del importe del viaje combinado.<br />
El importe restante hasta completar el precio total el viaje combinado, seg&uacute;n calendario de pagos informado al cliente con anterioridad a la contrataci&oacute;n del viaje, en el programa, y establecido en el contrato salvo en aquellos supuestos determinados en los que a raz&oacute;n del momento de la firma del contrato y la fecha de salida del viaje el pago se efectuar&aacute; en su integridad sin sometimiento a calendario de pago alguno.<br />
Si alguno de los servicios contratados tuviera condiciones especiales de pago, se informar&aacute; de las mismas en el programa antes de la contrataci&oacute;n del viaje y &eacute;stas se reflejar&aacute;n en el contrato.<br />
Si el viajero no realiza el pago final establecido en el contrato, SPAIN HERITAGE le requerir&aacute; para que lo efect&uacute;e en el plazo de 48 horas. De no procederse a este pago en tal plazo o de incumplirse cualesquiera de los pagos establecidos en el calendario, SPAIN HERITAGE podr&aacute; resolver el contrato y aplicar las reglas establecidas para el desistimiento antes de la salida por parte del consumidor que, de tener especificidades, &eacute;stas ser&aacute;n indicadas en el programa y el contrato. El viaje combinado debe estar totalmente pagado para que se le faciliten al viajero todas las prestaciones de su viaje. En el caso de que el cobro sea rechazado por cualquier causa, se proceder&aacute; a la anulaci&oacute;n de la reserva, previa comunicaci&oacute;n informativa para intentar solventar el problema del pago.<br />
Una vez efectuada la salida del viaje, la no utilizaci&oacute;n voluntaria por parte del consumidor de alguno de los servicios contratados, no le dar&aacute; derecho a devoluci&oacute;n alguna.<br />
5.5. Ofertas especiales<br />
Cuando se realice la contrataci&oacute;n del viaje combinado como consecuencia de ofertas especiales, de &uacute;ltima hora o equivalentes, a precio distinto del expresado anteriormente, los servicios comprendidos en el precio son &uacute;nicamente aquellos que se especifican detalladamente en la oferta, aun cuando, dicha oferta haga referencia a alguno de los programas descritos en este programa, siempre que dicha remisi&oacute;n se realice a los exclusivos efectos de informaci&oacute;n general del destino.</p>

<h2>6.- MODIFICACI&Oacute;N DEL CONTRATO ANTES DE LA SALIDA</h2>

<p>En cualquier caso los cambios ser&aacute;n reflejados en un Anexo que se incorporar&aacute; al contrato de viaje combinado inicialmente firmado entre las partes.<br />
6.1.- Por el organizador<br />
SPAIN HERITAGE, antes de la fecha de salida del viaje, se reserva su derecho a realizar aquellos cambios que sean necesarios para el buen fin del mismo siempre que estos no sean significativos y que se informar&aacute;n al viajero de forma clara, comprensible y destacada en un soporte duradero.<br />
En el supuesto de que SPAIN HERITAGE se vea obligada a realizar cambios sustanciales en alguna de las principales caracter&iacute;sticas de los servicios de viaje o no pueda cumplir con los requisitos relativos a las necesidades especiales o proponga al viajero aumentar el precio del viaje combinado en m&aacute;s de un 8% del total del precio, SPAIN HERITAGE lo pondr&aacute; inmediatamente en conocimiento del cliente y este podr&aacute; optar entre aceptar la modificaci&oacute;n del contrato y su repercusi&oacute;n en el precio, o la resoluci&oacute;n del contrato. El cliente deber&aacute; comunicar por escrito la decisi&oacute;n que adopte a SPAIN HERITAGE dentro de los tres d&iacute;as siguientes al d&iacute;a en el que se le notifique la modificaci&oacute;n. En el caso de que el viaje combinado sustitutivo al modificado sea de calidad o coste inferior, el viajero tendr&aacute; derecho a una reducci&oacute;n en el precio.<br />
6.2.- Por el cliente<br />
6.2.1.- Modificaci&oacute;n de las caracter&iacute;sticas del viaje combinado<br />
Si en cualquier momento anterior a la fecha de salida, el viajero desea solicitar cambios referidos a cualesquiera de las caracter&iacute;sticas del viaje combinado contratado y SPAIN HERITAGE puede efectuarlos, SPAIN HERITAGE podr&aacute; exigirle al viajero el abono de los gastos adicionales justificados que hubiese causado dicha modificaci&oacute;n. En este supuesto los precios y caracter&iacute;sticas de los servicios tur&iacute;sticos podr&aacute;n no corresponderse con los publicados en el programa o informaci&oacute;n precontractual que dio lugar a la contrataci&oacute;n.<br />
6.2.2.- Cesi&oacute;n del contrato de viaje combinado<br />
El viajero podr&aacute; ceder su reserva en el viaje combinado a una tercera persona siempre y cuando &eacute;ste re&uacute;na todas las condiciones requeridas para el citado viaje, debi&eacute;ndolo comunicar por escrito a SPAIN HERITAGE con una antelaci&oacute;n m&iacute;nima de 7 d&iacute;as a la fecha del inicio del viaje, salvo que las partes acuerden un plazo menor en el contrato, quien gestionar&aacute; con el proveedor e informar&aacute; de la posibilidad o no de poder llevar a cabo dicha cesi&oacute;n. Tanto el cedente como el cesionario responder&aacute;n solidariamente del pago del precio del viaje as&iacute; como de cualquier gasto adicional justificado que pudiera causar dicha cesi&oacute;n.</p>

<h2>7.- RESOLUCI&Oacute;N DEL CONTRATO</h2>

<p>7.1. Resoluci&oacute;n del viaje por parte de SPAIN HERITAGE<br />
En el supuesto de que SPAIN HERITAGE cancele el viaje combinado antes de la fecha de salida acordada, por cualquier motivo que no le sea imputable al cliente, &eacute;ste tendr&aacute; derecho, desde el momento en que se produzca la resoluci&oacute;n del contrato, al reembolso de todas las cantidades pagadas, con arreglo al mismo, o bien a la realizaci&oacute;n de otro viaje combinado de calidad equivalente o superior, siempre que el organizador pueda propon&eacute;rselo. En el supuesto de que el viaje ofrecido fuera de calidad inferior, el organizador deber&aacute; reembolsar al cliente, cuando proceda, en funci&oacute;n de las cantidades ya desembolsadas, la diferencia del precio, con arreglo al contrato.<br />
En los casos que la propuesta de viaje combinado est&eacute; condicionada a contar con un m&iacute;nimo de participantes (35 personas en viajes de autocar y 20 personas en viajes a&eacute;reos) y por no alcanzarse ese n&uacute;mero se produzca la anulaci&oacute;n del mismo, SPAIN HERITAGE deber&aacute; notific&aacute;rselo por escrito al cliente antes de la fecha l&iacute;mite fijada a tal fin, que como m&iacute;nimo ser&aacute; de 20 d&iacute;as de antelaci&oacute;n a la fecha prevista de iniciaci&oacute;n del viaje y al reembolso de todas las cantidades entregadas hasta ese momento.<br />
En el caso de que SPAIN HERITAGE resuelva el contrato de viaje combinado, deber&aacute; reembolsar al viajero las cantidades abonadas por el mismo, en un plazo no superior a catorce d&iacute;as naturales a contar desde la fecha de resoluci&oacute;n del contrato.<br />
7.2. Resoluci&oacute;n del viaje por parte del viajero<br />
(*) Derecho de desistimiento:<br />
Conforme al art. 97.1 del TRLGDU, se informa al usuario que, en el caso de servicios de viaje combinado no ser&aacute; de aplicaci&oacute;n el ejercicio del derecho de desistimiento, en virtud de lo dispuesto en su art. 103. Los servicios de viaje, transporte, comidas o actividades relacionadas con las actividades de esparcimiento est&aacute;n excluidos en la normativa aplicable de consumidores, en relaci&oacute;n al ejercicio del derecho de desistimiento por parte del consumidor.<br />
As&iacute; y en virtud del art&iacute;culo 103 apartado l) del Real Decreto Legislativo 1/2007, de 16 de noviembre, por el que se aprueba el texto refundido de la Ley General para la Defensa de los Consumidores y Usuarios y otras leyes complementarias, no ser&aacute; aplicable el derecho de desistimiento a los contratos de viaje combinado, debiendo el consumidor asumir los gastos derivados de la cancelaci&oacute;n de servicios solicitada por su parte. &Uacute;nicamente aquellos viajeros que contraten un viaje combinado fuera del establecimiento (no contrataci&oacute;n a distancia), seg&uacute;n definido en el RDL 1/2007, de 16 de diciembre, dispondr&aacute;n del plazo de catorce d&iacute;as para ejercer su derecho ante SPAIN HERITAGE.<br />
Aclarado lo anterior, en todo momento anterior a la fecha de salida el viajero resolver el contrato de viaje combinado, teniendo derecho a la devoluci&oacute;n de las cantidades que hubiera abonado, y debiendo abonar a SPAIN HERITAGE una penalizaci&oacute;n por los conceptos que a continuaci&oacute;n se indican, salvo que la resoluci&oacute;n tenga lugar por causa de fuerza mayor:<br />
Gastos de gesti&oacute;n:<br />
En el caso de servicios sueltos 10% por persona<br />
En el caso de viajes combinados 10% por persona<br />
M&aacute;s los gastos de anulaci&oacute;n que hubiere.<br />
b) Gastos de cancelaci&oacute;n: conforme las condiciones indicadas en el programa y firmadas en el contrato y seg&uacute;n detalle del documento de confirmaci&oacute;n de viaje o, en su defecto, el 5% del total del viaje si el desistimiento se produce con m&aacute;s de diez d&iacute;as y menos de quince de antelaci&oacute;n a la fecha de comienzo del viaje; el 15% entre los d&iacute;as 3 y 10, y el 25% dentro de las cuarenta y ocho horas anteriores a la salida.<br />
De no presentarse a la salida, el cliente no tendr&aacute; derecho a devoluci&oacute;n alguna de la cantidad desembolsada, abonando, en su caso, las cantidades pendientes, salvo acuerdo entre las partes en otro sentido.<br />
En el caso de que alguno de los servicios contratados y anulados estuviera sujeto a condiciones econ&oacute;micas especiales de contrataci&oacute;n los gastos de cancelaci&oacute;n por desistimiento ser&aacute;n los establecidos por el proveedor de cada servicio. La resoluci&oacute;n produce efectos desde el momento en que la voluntad de resolver del cliente llega al conocimiento de SPAIN HERITAGE, debiendo comunic&aacute;rsela por escrito en horario laboral. Conocida la resoluci&oacute;n, SPAIN HERITAGE reembolsar&aacute; al cliente las cantidades que &eacute;ste hubiese abonado, deducidos los gastos de gesti&oacute;n y, en su caso, los gastos de anulaci&oacute;n y las penalizaciones correspondientes.<br />
En relaci&oacute;n con el seguro contra gastos de cancelaci&oacute;n contratado por el viajero, en ning&uacute;n caso la prima abonada por el mismo ser&aacute; reembolsable.<br />
Tendr&aacute;n derecho a la resoluci&oacute;n del contrato y al reembolso total del precio del viaje combinado:<br />
. aquellos viajeros en cuyos viajes contratados concurran circunstancias inevitables y extraordinarias en el lugar de destino, o en las inmediaciones, que afecten de forma significativa a la ejecuci&oacute;n al viaje o al transporte de pasajeros al lugar de destino.<br />
. si se modifica significativamente alguno de los elementos esenciales del viaje combinado que no sea el precio.<br />
. en el supuesto de que el empresario responsable del viaje combinado, lo cancele antes del inicio del viaje, sin que concurran circunstancias extraordinarias, el viajero tendr&aacute; derecho adem&aacute;s a recibir una compensaci&oacute;n.<br />
. en caso de no ejecuci&oacute;n de los servicios cuando ello afecte sustancialmente a la ejecuci&oacute;n del viaje combinado y el organizador o, en su caso, el minorista, no consigan solucionar el problema.</p>

<h2>8.- DERECHOS Y DEBERES DE LAS PARTES DESPU&Eacute;S DE INICIAR EL VIAJE</h2>

<p>En caso de producirse alguna falta de conformidad en cualquiera de los servicios de viaje contratados, el cliente deber&aacute; ponerlo en conocimiento, sin demora indebida, del prestatario de los mismos, corresponsal local o bien directamente a SPAIN HERITAGE. Tras recibir la comunicaci&oacute;n estos deber&aacute;n obrar con diligencia para hallar las soluciones adecuadas que permitan la continuaci&oacute;n del viaje.<br />
Si cualquiera de los servicios incluidos en el viaje no se ejecuta de conformidad con el contrato, SPAIN HERITAGE deber&aacute; subsanar la falta de conformidad, salvo que resulte imposible o si ello entra&ntilde;a un coste desproporcionado, teniendo en cuenta la gravedad de la falta de conformidad y el valor de los servicios de viaje afectados. En caso de que con arreglo a este apartado no se subsane la falta de conformidad ser&aacute; de aplicaci&oacute;n la reducci&oacute;n del precio e indemnizaci&oacute;n de da&ntilde;os y perjuicios.<br />
Si SPAIN HERITAGE no subsana la falta de conformidad en un plazo razonable establecido por el viajero, el propio viajero podr&aacute; hacerlo y solicitar el reembolso de los gastos necesarios. No ser&aacute; preciso que el viajero especifique un plazo l&iacute;mite si SPAIN HERITAGE se niega a subsanar la falta de conformidad o si se requiere una soluci&oacute;n inmediata. Cuando una proporci&oacute;n significativa de los servicios de viaje no pueda prestarse seg&uacute;n lo convenido en el contrato de viaje combinado, SPAIN HERITAGE ofrecer&aacute;, sin coste adicional alguno para el viajero, f&oacute;rmulas alternativas adecuadas, de ser posible de calidad equivalente o superior a las especificadas en el contrato, para la continuaci&oacute;n del viaje combinado, tambi&eacute;n cuando el regreso del viajero al lugar de salida no se efect&uacute;e seg&uacute;n lo acordado. Si las f&oacute;rmulas alternativas propuestas dan lugar a un viaje combinado de menor calidad que la especificada en el contrato, el organizador o, en su caso, el minorista aplicar&aacute; al viajero una reducci&oacute;n adecuada del precio. El viajero solo podr&aacute; rechazar las f&oacute;rmulas alternativas propuestas si no son comparables a lo acordado en el contrato de viaje combinado o si la reducci&oacute;n del precio concedida es inadecuada.<br />
Cuando una falta de conformidad afecte sustancialmente a la ejecuci&oacute;n del viaje y SPAIN HERITAGE no la hayan subsanado en un plazo razonable establecido por el viajero, este podr&aacute; poner fin al contrato sin pagar ninguna penalizaci&oacute;n y solicitar, en su caso, tanto una reducci&oacute;n del precio como una indemnizaci&oacute;n por los da&ntilde;os y perjuicios causados. Si el viaje combinado incluye el transporte de pasajeros, SPAIN HERITAGE, repatriar&aacute; adem&aacute;s al viajero en un transporte equivalente sin dilaciones indebidas y sin coste adicional.<br />
Si es imposible garantizar el retorno del viajero seg&uacute;n lo convenido en el contrato debido a circunstancias inevitables y extraordinarias, SPAIN HERITAGE asumir&aacute; el coste del alojamiento que sea necesario, de ser posible de categor&iacute;a equivalente, por un per&iacute;odo no superior a tres noches por viajero. Cuando la normativa europea sobre derechos de los pasajeros, aplicable a los correspondientes medios de transporte para el regreso del viajero, establezca per&iacute;odos m&aacute;s largos, se aplicar&aacute;n dichos per&iacute;odos. La limitaci&oacute;n de costes a que se refiere el apartado anterior no se aplicar&aacute; a las personas con discapacidad o movilidad reducida, tal como se definen en el art&iacute;culo 2.a) del Reglamento (CE) n.&ordm; 1107/2006 del Parlamento Europeo y del Consejo, de 5 de julio de 2006, sobre los derechos de las personas con discapacidad o movilidad reducida en el transporte a&eacute;reo, ni a sus acompa&ntilde;antes, mujeres embarazadas y menores no acompa&ntilde;ados, as&iacute; como a las personas con necesidad de asistencia m&eacute;dica espec&iacute;fica, si sus necesidades particulares han sido participadas al organizador o, en su caso, al minorista al menos cuarenta y ocho horas antes del inicio del viaje. El organizador y el minorista no podr&aacute;n invocar las circunstancias inevitables y extraordinarias a efectos de la limitaci&oacute;n de responsabilidad, si el transportista no puede acogerse a estas circunstancias en virtud de la normativa europea.<br />
El viajero deber&aacute; atenerse, en todo caso, a las indicaciones que le facilite SPAIN HERITAGE para la adecuada ejecuci&oacute;n del viaje, as&iacute; como a las reglamentaciones que son de general aplicaci&oacute;n a los usuarios de los servicios comprendidos en el viaje combinado. En particular, observar&aacute; una conducta que no perjudique su normal desarrollo. En todo caso, el cliente est&aacute; obligado a tomar las medidas adecuadas y razonables para intentar reducir los da&ntilde;os que puedan derivarse de la no ejecuci&oacute;n o ejecuci&oacute;n deficiente del contrato o para evitar que se agraven. Los da&ntilde;os que deriven de no haber adoptado dichas medidas ser&aacute;n de cuenta del viajero y SPAIN HERITAGE no deber&aacute; responder por ellos. La infracci&oacute;n grave de estos deberes faculta al organizador para resolver el contrato de contrato de viaje combinado por causa imputable al cliente, respondiendo este &uacute;ltimo de los da&ntilde;os y perjuicios que se puedan haber irrogado al organizador.</p>

<h2>9- RESPONSABILIDAD DE SPAIN HERITAGE</h2>

<p>9.1. Respecto de la Responsabilidad Solidaria<br />
SPAIN HERITAGE responder&aacute; de forma solidaria frente al viajero del correcto cumplimiento de los servicios de viaje con independencia de que estos servicios los deba ejecutar &eacute;l mismo u otros prestadores de servicios, y sin perjuicio del derecho de SPAIN HERITAGE de ejercer su derecho de repetici&oacute;n contra dichos prestadores de servicios.<br />
9.2. Respecto del deber de SPAIN HERITAGE de prestar asistencia<br />
SPAIN HERITAGE facilitar&aacute; unos datos de contacto al viajero para prestarle la necesaria asistencia cuando &eacute;ste se encuentre en dificultades.<br />
SPAIN HERITAGE deber&aacute; proporcionar asistencia adecuada y sin demora indebida al viajero en dificultades, en especial en caso de circunstancias inevitables y extraordinarias, en particular mediante el suministro de informaci&oacute;n adecuada sobre los servicios sanitarios, las autoridades locales y la asistencia consular; la asistencia al viajero para establecer comunicaciones a distancia; y la ayuda para encontrar f&oacute;rmulas de viaje alternativas. SPAIN HERITAGE podr&aacute; facturar un recargo razonable por dicha asistencia si la dificultad se ha originado intencionadamente o por negligencia del viajero. Dicho recargo no superar&aacute; en ning&uacute;n caso los costes reales en los que haya incurrido SPAIN HERITAGE.<br />
9.3. Respecto de la responsabilidad por errores en la reserva<br />
SPAIN HERITAGE ser&aacute; responsable de los errores debidos a defectos t&eacute;cnicos que se produzcan en el sistema de reservas que le sean atribuibles, as&iacute; como de los errores cometidos durante el proceso de reserva, cuando SPAIN HERITAGE haya aceptado gestionar la reserva de un viaje combinado no siendo nunca responsable de los errores de reserva atribuibles al viajero o causados por circunstancias inevitables y extraordinarias.</p>

<h2>10.-L&Iacute;MITES DE LA RESPONSABILIDAD DE ORGANIZADOR</h2>

<p>Cuando las prestaciones del contrato de viaje combinado est&eacute;n regidas por convenios internacionales, el resarcimiento de los da&ntilde;os corporales y no corporales que resulten del incumplimiento o de la mala ejecuci&oacute;n de las mismas estar&aacute; sujeto a las limitaciones que estos establezcan.<br />
Por lo que se refiere a los da&ntilde;os que no sean corporales, &eacute;stos deber&aacute;n ser siempre acreditados por el consumidor. En ning&uacute;n caso SPAIN HERITAGE se responsabiliza de los gastos de alojamiento, manutenci&oacute;n, transportes y otros que se originen por causas de fuerza mayor. Cuando el viaje se efect&uacute;e en autocares, &ldquo;vans&rdquo;, limusinas y similares contratadas por SPAIN HERITAGE directa o indirectamente, y ocurra un accidente, cualquiera que sea el pa&iacute;s donde se produzca, el consumidor habr&aacute; de presentar la pertinente reclamaci&oacute;n contra la entidad transportista en el respectivo pa&iacute;s, a fin de salvaguardar, en su caso, la indemnizaci&oacute;n del seguro de &eacute;sta, siendo auxiliado y asesorado gratuitamente en sus gestiones por SPAIN HERITAGE.<br />
La custodia del equipaje no forma parte del contrato de viaje.<br />
A todos los efectos y en cuanto al transporte terrestre se refiere, se entender&aacute; que el equipaje y dem&aacute;s enseres personales del usuario los conserva consigo, cualquiera que sea la parte del veh&iacute;culo en que vayan colocados, y que se transporta por cuenta y riesgo del usuario. Se recomienda a los usuarios que est&eacute;n presentes en todas las manipulaciones de carga y descarga de los equipajes. Siendo el billete de pasaje el documento vinculante entre las citadas compa&ntilde;&iacute;as y el pasajero. En el supuesto de sufrir alg&uacute;n da&ntilde;o o extrav&iacute;o el consumidor deber&aacute;, presentar, en el acto, la oportuna reclamaci&oacute;n a la Compa&ntilde;&iacute;a de Transportes. SPAIN HERITAGE se compromete a prestar la oportuna asistencia a los clientes que puedan verse afectados por alguna de estas circunstancias.</p>

<h2>11.- INFORMACION DOCUMENTAL Y SANITARIA</h2>

<p>SPAIN HERITAGE informar&aacute; al cliente sobre las formalidades sanitarias necesarias para el viaje y la estancia, respondiendo de la correcci&oacute;n de la informaci&oacute;n que facilite.<br />
El viajero ser&aacute; el responsable de obtener la documentaci&oacute;n necesaria para la realizaci&oacute;n del viaje, incluido pasaporte, visados y la referida a las formalidades sanitarias debiendo asegurarse antes de iniciar el viaje, de cumplir con todas las normas y requisitos aplicables.<br />
Asimismo, es responsabilidad del viajero el pedir una cita en el centro de vacunaci&oacute;n internacional m&aacute;s cercano con la debida antelaci&oacute;n, siendo estos los &uacute;nicos competentes en materia sobre la prevenci&oacute;n y la seguridad en cuanto al riesgo de contagio de enfermedades propias de terceros pa&iacute;ses, y los &uacute;nicos autorizados para hacer cumplir la legislaci&oacute;n internacional vigente sobre medicina tropical y preventiva.<br />
Los menores de 18 a&ntilde;os deben llevar un permiso escrito, firmado por sus padres o tutores, en previsi&oacute;n de que el mismo pueda ser solicitado por cualquier autoridad.<br />
Todos los da&ntilde;os que puedan derivarse de la falta de esa documentaci&oacute;n ser&aacute;n por cuenta del viajero y, en particular, los gastos producidos por la interrupci&oacute;n del viaje y su eventual repatriaci&oacute;n.</p>

<h2>12.- SEGUROS</h2>

<p>SPAIN HERITAGE informa al consumidor sobre la posibilidad de suscripci&oacute;n facultativa de un seguro que le cubra los gastos de cancelaci&oacute;n y/o de asistencia en caso de p&eacute;rdida o deterioro de equipaje, accidente, enfermedad, repatriaci&oacute;n o fallecimiento.</p>

<h2>13.-RECLAMACIONES, ACCIONES JUDICIALES Y PRESCRIPCI&Oacute;N</h2>

<p>Las presentes Condiciones Generales estar&aacute;n sometidas a la legislaci&oacute;n espa&ntilde;ola. Las partes se someten, a su elecci&oacute;n, para la resoluci&oacute;n de los conflictos y con renuncia a cualquier otro fuero, a los juzgados y tribunales del domicilio del usuario.<br />
El cliente podr&aacute; reclamar en v&iacute;a judicial ante los tribunales del lugar donde tenga su domicilio para resolver las discrepancias o reclamaciones que susciten la interpretaci&oacute;n o la ejecuci&oacute;n del contrato de viaje combinado y de estas CONDICIONES GENERALES.<br />
Las acciones derivadas de los derechos reconocidos en la Ley en relaci&oacute;n con el contrato de viaje combinado prescriben por el transcurso del plazo de dos a&ntilde;os desde el d&iacute;a de celebraci&oacute;n del contrato. En todo caso, y con pleno respeto a lo dispuesto en el apartado precedente, SPAIN HERITAGE pone a disposici&oacute;n de los clientes la siguiente informaci&oacute;n para que puedan interponer sus quejas y reclamaciones o solicitar informaci&oacute;n sobre el viaje contratado:<br />
Los usuarios, en caso de discrepancias o incidencias con los servicios contratados, podr&aacute;n dirigirse a trav&eacute;s de email a la agencia de viajes a la siguiente direcci&oacute;n: info@spainheritagenetwork.com, o a trav&eacute;s de correo postal a SPAIN HERITAGE TOURS S.L. Rodríguez Marín 61 &ndash; Bajo Izquierda, 28016 - Madrid. En virtud de la normativa de consumidores y usuarios se informa que SPAIN HERITAGE no se encuentra adherido a ning&uacute;n sistema arbitral de consumo, si bien y en cumplimiento de las disposiciones de La Comisi&oacute;n Europea Reglamento (UE) n.&ordm; 524/2013 proporcionamos la informaci&oacute;n sobre la plataforma RLL y direcci&oacute;n de acceso: https://ec.europa.eu/consumers/odr</p>

<h2>14.- PROTECCI&Oacute;N DE DATOS</h2>

<p>En cumplimiento de la Ley Org&aacute;nica 3/2018, de 5 de diciembre, de Protecci&oacute;n de Datos Personales y garant&iacute;a de derechos digitales y del Reglamento Europeo 2016/679, relativo a la protecci&oacute;n de las personas f&iacute;sicas en lo que respecta al tratamiento de datos personales y a la libre circulaci&oacute;n de estos datos, la agencia y el viajero se comprometen a respetar y cumplir con la normativa vigente en dicha materia. El usuario al acceder, navegar y/o usar el Sitio Web consiente el tratamiento de dicha informaci&oacute;n y datos y declara que toda la informaci&oacute;n o datos que facilita son veraces.<br />
Con la adhesi&oacute;n a las presentes Condiciones Generales por su aceptaci&oacute;n expresa indicada en la solicitud del viaje por v&iacute;a telem&aacute;tica y la aceptaci&oacute;n de las Condiciones Particulares, se presta el consentimiento expreso a que los datos personales que se proporcionen ser&aacute;n tratados como responsable por SPAIN HERITAGE. Las finalidades del tratamiento ser&aacute;n gestionar adecuadamente la reserva y la adecuada prestaci&oacute;n de los servicios contratados, as&iacute; como poder hacer llegar informaci&oacute;n sobre productos, servicios, ofertas y promociones relativas tanto al programa como a acciones ligadas a la actividad de SPAIN HERITAGE, en el &aacute;mbito del turismo, la hosteler&iacute;a, la restauraci&oacute;n y los viajes. Los datos s&oacute;lo ser&aacute;n cedidos, &uacute;nica y exclusivamente, a terceros que intervengan de forma necesaria en la prestaci&oacute;n de servicios vinculados al objeto del viaje contratado y con id&eacute;nticas finalidades a las anteriores, ellos con salvaguardia del deber de secreto y aplicaci&oacute;n de las medidas de seguridad exigidas por la normativa en vigor. Se podr&aacute;n ejercer los derechos de acceso, rectificaci&oacute;n, cancelaci&oacute;n u oposici&oacute;n a su tratamiento, por medio de escrito acompa&ntilde;ado de copia del Documento Nacional de Identidad o pasaporte en vigor y dirigido por correo a la anterior direcci&oacute;n o por correo electr&oacute;nico (protecciondedatos@spainheritagenetwork.com ), a la atenci&oacute;n del responsable de Protecci&oacute;n de Datos. En caso de que efectivamente llegue a contratar con la agencia, la informaci&oacute;n relativa a protecci&oacute;n de datos se proporciona al usuario y se encuentra a su disposici&oacute;n a trav&eacute;s de nuestra pol&iacute;tica de privacidad y protecci&oacute;n de datos en www.spainheritagenetwork.com</p>

<h2>15.- Vigencia</h2>

<p>SPAIN HERITAGE se reserva el derecho a modificar, limitar o cancelar estas Condiciones Generales en cualquier momento. En cualquier caso ser&aacute;n de aplicaci&oacute;n las condiciones que estuviesen en vigor cuando el cliente formaliz&oacute; la reserva, o las posteriores si &eacute;stas fuesen m&aacute;s favorables para los intereses del consumidor.</p>

<p>&nbsp;</p>
                                 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </default-layout>
</template>

<script>

import DefaultLayout from '../layouts/DefaultLayout.vue'

export default {
    components:{
        DefaultLayout, 
        // IconPalace, 
        // IconRealPalace, 
        // IconArt, 
        // IconWalledEnclosure,
        // IconMonastery, 
        // Cards4,
        // CardsThumbnails// Cards
    },
    data(){
        return{
       
        }
    },
    methods:{
        goToCastillosYPalacios(){
            let $vm = this;
            $vm.$router.push('/castillos')
        }
    },
    setup() {
        
    },
    created(){
       
    },
    computed: {

    }
}
</script>


<style scoped>
    .text-columns{
        column-count: 3;
        /* height: 50vh; */
    }
    .disable-selector{
        color:rgba(225,225,225);
    }
    .slide-show-container{
        position: relative;
        width: 100%;
        margin: auto;
    }
    .spainhn-slides{
        display: none;
    }

    /* Next & previous buttons */
    .prev, .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    margin-top: -22px;
    padding: 16px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
    }

    /* Position the "next button" to the right */
    .next {
    right: 0;
    border-radius: 3px 0 0 3px;
    }
    
    /* Fading animation */
    .fade {
        animation-name: fade;
        animation-duration: 10s;
    }
    .general-main p{
        width: 100%;
        overflow-wrap: break-word;
    }

    @keyframes fade {
        from {opacity: .4}
        to {opacity: 1}
    }

        @media (min-width: 768px) {
        .text-columns{
            column-count: 3;
            /* height: 50vh; */
        }
    }

    @media (max-width: 768px){.text-columns{
        column-count: 2;
        /* height: 50vh; */
    }}

    @media (max-width: 424px){
        .text-columns{
        column-count: 1;
        /* height: 50vh; */
    }
    .margen-estandard h1{
        font-size: 20px;
    }
    }
</style>